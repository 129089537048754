import React from "react";
import WorksIcon from "../../../../../Images/AWSManageService/hireOutsourceIcon3.png";

const HowAwsWorks = () => {
  return (
    <div className="bg-[#09365B] py-14">
      <div className="container mx-auto px-5 lg:px-20 text-white">
        <div className="text-center">
          <h1 className="text-2xl font-bold">HOW AWS WORKS</h1>
          <h1 className="text-3xl lg:text-4xl font-bold mt-2">
            AWS Cloud Management Benefits
          </h1>
        </div>
        <div className="grid gap-0 lg:gap-8 grid-cols-1 lg:grid-cols-2 my-10 items-center justify-items-center">
          <div>
            <div className="flex items-center mt-4">
              <img src={WorksIcon} alt="" className="mr-2" />
              <p className="text-lg leading-7">
                We follow AWS best practices for security, <br /> optimization
                and high-availability for any kind of <br />
                public cloud architectures.
              </p>
            </div>
            <div className="flex items-center mt-4 lg:mt-16">
              <img src={WorksIcon} alt="" className="mr-2" />
              <p className="text-lg leading-7">
                We follow AWS best practices for security, <br /> optimization
                and high-availability for any kind of <br />
                public cloud architectures.
              </p>
            </div>
            <div className="flex items-center mt-4 lg:mt-16">
              <img src={WorksIcon} alt="" className="mr-2" />
              <p className="text-lg leading-7">
                We follow AWS best practices for security, <br /> optimization
                and high-availability for any kind of <br />
                public cloud architectures.
              </p>
            </div>
          </div>
          <div>
            <div className="flex items-center mt-4">
              <img src={WorksIcon} alt="" className="mr-2" />
              <p className="text-lg leading-7">
                We follow AWS best practices for security, <br /> optimization
                and high-availability for any kind of <br />
                public cloud architectures.
              </p>
            </div>
            <div className="flex items-center mt-4 lg:mt-16">
              <img src={WorksIcon} alt="" className="mr-2" />
              <p className="text-lg leading-7">
                We follow AWS best practices for security, <br /> optimization
                and high-availability for any kind of <br />
                public cloud architectures.
              </p>
            </div>
            <div className="flex items-center mt-4 lg:mt-16">
              <img src={WorksIcon} alt="" className="mr-2" />
              <p className="text-lg leading-7">
                We follow AWS best practices for security, <br /> optimization
                and high-availability for any kind of <br />
                public cloud architectures.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowAwsWorks;
