import React from "react";
import Accelete from "./Section/Accelarate/Accelete";
import Banner from "./Section/Banner/Banner";
import CaseStudies from "./Section/CaseStudies/CaseStudies";
import Learn from "./Section/Learn/Leran";
import LearnMore from "./Section/LearnMore/LearnMore";
import ServicePlan from "./Section/ServicePlan/ServicePlan";

const Home = () => {
  return (
    <div className="mt-20">
      <Banner />
      <ServicePlan />
      <br />
      <Learn />
      <br />
      <CaseStudies />
      <Accelete />
      <LearnMore />
    </div>
  );
};

export default Home;
