import React from "react";

const ManageAwsCould = () => {
  return (
    <div>
      <div className="container mx-auto px-5 lg:px-20 my-14">
        <div className="text-center">
          <h1 className="text-2xl text-blue-700 font-bold">
            MANAGED AWS CLOUD
          </h1>
          <h1 className="text-3xl lg:text-4xl mt-2 text-black font-bold">
            Fill digital-workflow gaps with AWS
          </h1>
          <p className="text-lg mt-6">
            Get ongoing management and monitoring of your AWS Cloud resources &
            applications. With <b>AWS Managed services</b>, we operate your{" "}
            <br />
            infrastructure and optimize it for scalability, fault tolerance,
            security and more.
          </p>
          <p className="text-lg mt-5">
            Improve security and automate common activities through{" "}
            <b>Managed AWS Cloud Services</b>. Reduce the time administrating
            your <br />
            application and any risk with the AWS Cloud.
          </p>
        </div>
      </div>
      <div className="bg-gradient-to-r from-[#11278A] to-[#0071BB] py-10 ">
        <div className="container mx-auto px-5 lg:px-20 lg:flex items-center justify-around">
          <h1 className="text-3xl lg:text-5xl text-white text-right font-medium leading-9">
            AWS Services: <br />
            Reduce Cost & Boost Operational <br />
            Efficiently
          </h1>
          <div>
            <button className="btn rounded-full bg-transparent border-2 px-12 lg:mt-8 hover:border-[#ff6700] hover:bg-transparent border-[#ff6700]">
              Contact us!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAwsCould;
