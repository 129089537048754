import React from "react";

const DevOpsBenefitsOfFintech = () => {
  return (
    <div className="bg-[#09365B]">
      <div className="container mx-auto px-5 lg:px-20 text-white py-14">
        <h1 className="text-center font-bold text-2xl">
          DEVOPS BENEFITS FOR YOUR FINTECH COMPANY
        </h1>
        <div className="lg:flex items-center justify-around mt-3">
          <div>
            <h1 className="text-4xl font-medium mt-8">
              Operational <br /> Benefits
            </h1>
            <div className="ml-3">
              <p className=" text-slate-300 text-lg">
                <i class="fa-regular fa-circle-check text-lg text-sky-400 mr-3 mt-3"></i>
                Better security and protection.
              </p>
              <p className=" text-slate-300 text-lg">
                <i class="fa-regular fa-circle-check text-lg text-sky-400 mr-3 mt-3"></i>
                Faster releases to market.
              </p>
              <p className=" text-slate-300 text-lg">
                <i class="fa-regular fa-circle-check text-lg text-sky-400 mr-3 mt-3"></i>
                ​Overall cost optimization.
              </p>
              <p className=" text-slate-300 text-lg">
                <i class="fa-regular fa-circle-check text-lg text-sky-400 mr-3 mt-3"></i>
                Potential to scale as desired.
              </p>
              <p className=" text-slate-300 text-lg">
                <i class="fa-regular fa-circle-check text-lg text-sky-400 mr-3 mt-3"></i>
                Optimization of businesses processes.
              </p>
            </div>
          </div>
          <div>
            <h1 className="text-4xl font-medium mt-8">
              Business <br /> Benefits
            </h1>
            <div className="ml-3">
              <p className=" text-slate-300 text-lg">
                <i class="fa-regular fa-circle-check text-lg text-sky-400 mr-3 mt-3"></i>
                Cost savings.
              </p>
              <p className=" text-slate-300 text-lg">
                <i class="fa-regular fa-circle-check text-lg text-sky-400 mr-3 mt-3"></i>
                Bigger revenues.
              </p>
              <p className=" text-slate-300 text-lg">
                <i class="fa-regular fa-circle-check text-lg text-sky-400 mr-3 mt-3"></i>
                Business growth.
              </p>
              <p className=" text-slate-300 text-lg">
                <i class="fa-regular fa-circle-check text-lg text-sky-400 mr-3 mt-3"></i>
                Customer satisfaction.
              </p>
              <p className=" text-slate-300 text-lg">
                <i class="fa-regular fa-circle-check text-lg text-sky-400 mr-3 mt-3"></i>
                Reliability on your business.
              </p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button className="btn rounded-full bg-transparent border-2 px-12 mt-10 hover:border-[#ff6700] hover:bg-transparent border-[#ff6700]">
            Contact us!
          </button>
        </div>
      </div>
    </div>
  );
};

export default DevOpsBenefitsOfFintech;
