import React from "react";
import SaasAppDevelopmentImg from "../../../../../Images/sassApplicationDevelopment/SaasAppDevelopment.png";

const SaasAppDevelopment = () => {
  return (
    <div className="bg-gradient-to-r from-[#11278A] to-[#0071BB] py-10">
      <div className="container mx-auto px-5 lg:px-20 py-10">
        <h1 className="lg:text-5xl text-3xl font-medium text-white text-center">
          SaaS App Development Process
        </h1>
        <div className="flex justify-center">
          <img src={SaasAppDevelopmentImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SaasAppDevelopment;
