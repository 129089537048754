import React from "react";
import ToolsImg1 from "../../../../../Images/FinancialServices/toolsImg1.png";
import ToolsImg2 from "../../../../../Images/FinancialServices/toolsImg2.png";
import ToolsImg3 from "../../../../../Images/FinancialServices/toolsImg3.png";
import ToolsImg4 from "../../../../../Images/FinancialServices/toolsImg4.png";
import ToolsImg5 from "../../../../../Images/FinancialServices/toolsImg5.png";
import ToolsImg6 from "../../../../../Images/FinancialServices/toolsImg6.png";

const ToolsAndTechnologies = () => {
  return (
    <div className="container mx-auto px-5 lg:px-20 my-14">
      <h1 className="text-center font-medium lg:text-4xl text-3xl">
        Tools & technologies that we can enable on <br />
        your Fintech business to potentialize it
      </h1>
      <div className="grid gap-0 lg:gap-8 grid-cols-1 lg:grid-cols-3 items-center justify-items-center">
        <div>
          <div className="p-5 shadow-lg border-2 hover:shadow-2xl hover:border-blue-700 border border-sky-500 hover:rounded-none rounded-lg duration-200 mt-6 lg:h-96 flex items-center">
            <div className="">
              <h1 className="text-blue-700 text-center text-3xl font-medium">
                Containerization
              </h1>
              <div>
                <div className="flex items-center mt-4">
                  <p className="mr-2">
                    <i class="fa-regular fa-circle-check text-sky-400"></i>
                  </p>
                  <p className="text-base text-slate-500">
                    {" "}
                    ​Isolate your application from <br />
                    attack
                  </p>
                </div>
              </div>
              <div className="flex items-center mt-4">
                <p className="mr-2">
                  <i class="fa-regular fa-circle-check text-sky-400"></i>
                </p>
                <p className="text-base text-slate-500">
                  {" "}
                  Split your app components and <br />
                  be prepared for emergencies
                </p>
              </div>
              <div className="mt-3">
                <img src={ToolsImg1} alt="" />
              </div>
            </div>
          </div>
          <div className="p-5 shadow-lg border-2 hover:shadow-2xl hover:border-blue-700 border border-sky-500 hover:rounded-none rounded-lg duration-200 mt-6 lg:h-96 flex items-center">
            <div className="">
              <h1 className="text-blue-700 text-center text-3xl font-medium">
                Monitoring and <br />
                Maintenance
              </h1>
              <div>
                <div className="flex items-center mt-4">
                  <p className="mr-2">
                    <i class="fa-regular fa-circle-check text-sky-400"></i>
                  </p>
                  <p className="text-base text-slate-500">
                    {" "}
                    Have a constant vision on what's
                    <br />
                    happening in your application <br />
                    and cloud
                  </p>
                </div>
              </div>
              <div className="flex items-center mt-4">
                <p className="mr-2">
                  <i class="fa-regular fa-circle-check text-sky-400"></i>
                </p>
                <p className="text-base text-slate-500">
                  {" "}
                  Get real-time reports on issues <br />
                  and downtimes
                </p>
              </div>
              <div className="mt-3">
                <img src={ToolsImg2} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="p-5 shadow-lg border-2 hover:shadow-2xl hover:border-blue-700 border border-sky-500 hover:rounded-none rounded-lg duration-200 mt-6 lg:h-96 flex items-center">
            <div className="">
              <h1 className="text-blue-700 text-center text-3xl font-medium">
                Failover
              </h1>
              <div>
                <div className="flex items-center mt-4">
                  <p className="mr-2">
                    <i class="fa-regular fa-circle-check text-sky-400"></i>
                  </p>
                  <p className="text-base text-slate-500">
                    {" "}
                    Scale your application according
                    <br />
                    to the demand levell
                  </p>
                </div>
              </div>
              <div className="flex items-center mt-4">
                <p className="mr-2">
                  <i class="fa-regular fa-circle-check text-sky-400"></i>
                </p>
                <p className="text-base text-slate-500">
                  {" "}
                  Have your backup cloud
                  <br />
                  resources in case of disaster
                </p>
              </div>
              <div className="mt-3">
                <img src={ToolsImg3} alt="" />
              </div>
            </div>
          </div>
          <div className="p-5 shadow-lg border-2 hover:shadow-2xl hover:border-blue-700 border border-sky-500 hover:rounded-none rounded-lg duration-200 mt-6 lg:h-96 flex items-center">
            <div className="">
              <h1 className="text-blue-700 text-center text-3xl font-medium">
                Serverless
              </h1>
              <div>
                <div className="flex items-center mt-4">
                  <p className="mr-2">
                    <i class="fa-regular fa-circle-check text-sky-400"></i>
                  </p>
                  <p className="text-base text-slate-500">
                    {" "}
                    ​Automate tasks and optimize
                    <br />
                    your operations with no servers <br />
                    required​
                  </p>
                </div>
              </div>
              <div className="flex items-center mt-4">
                <p className="mr-2">
                  <i class="fa-regular fa-circle-check text-sky-400"></i>
                </p>
                <p className="text-base text-slate-500">
                  {" "}
                  ​Speed up your app tasks, jobs,
                  <br />
                  and functions for better business <br /> performance at low
                  costs​
                </p>
              </div>
              <div className="mt-3">
                <img src={ToolsImg4} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="p-5 shadow-lg border-2 hover:shadow-2xl hover:border-blue-700 border border-sky-500 hover:rounded-none rounded-lg duration-200 mt-6 lg:h-96 flex items-center">
            <div className="">
              <h1 className="text-blue-700 text-center text-3xl font-medium">
                DevSecOps​
              </h1>
              <div>
                <div className="flex items-center mt-4">
                  <p className="mr-2">
                    <i class="fa-regular fa-circle-check text-sky-400"></i>
                  </p>
                  <p className="text-base text-slate-500">
                    {" "}
                    ​Secure all the steps of your
                    <br />
                    development cycle​
                  </p>
                </div>
              </div>
              <div className="flex items-center mt-4">
                <p className="mr-2">
                  <i class="fa-regular fa-circle-check text-sky-400"></i>
                </p>
                <p className="text-base text-slate-500">
                  {" "}
                  Remove the security breaches
                  <br />
                  on your release process
                </p>
              </div>
              <div className="mt-3">
                <img src={ToolsImg5} alt="" />
              </div>
            </div>
          </div>
          <div className="p-5 shadow-lg border-2 hover:shadow-2xl hover:border-blue-700 border border-sky-500 hover:rounded-none rounded-lg duration-200 mt-6 lg:h-96 flex items-center">
            <div className="">
              <h1 className="text-blue-700 text-center text-3xl font-medium">
                Big Data​
              </h1>
              <div>
                <div className="flex items-center mt-4">
                  <p className="mr-2">
                    <i class="fa-regular fa-circle-check text-sky-400"></i>
                  </p>
                  <p className="text-base text-slate-500">
                    {" "}
                    Make your database and
                    <br />
                    application stronger for <br />
                    attending your demand
                  </p>
                </div>
              </div>
              <div className="flex items-center mt-4">
                <p className="mr-2">
                  <i class="fa-regular fa-circle-check text-sky-400"></i>
                </p>
                <p className="text-base text-slate-500">
                  {" "}
                  Improve the user experience
                  <br />
                  with faster consults
                </p>
              </div>
              <div className="mt-3">
                <img src={ToolsImg6} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolsAndTechnologies;
