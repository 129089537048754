import React from "react";
import CaptureImg1 from "../../../../../Images/sassApplicationDevelopment/captureMarketImg1.png";
import CaptureImg2 from "../../../../../Images/sassApplicationDevelopment/captureMarketImg2.png";
import CaptureImg3 from "../../../../../Images/sassApplicationDevelopment/captureMarketImg3.png";
import CaptureIcon1 from "../../../../../Images/sassApplicationDevelopment/captureMarketIcon1.png";
import CaptureIcon2 from "../../../../../Images/sassApplicationDevelopment/captureMarketIcon2.png";
import CaptureIcon3 from "../../../../../Images/sassApplicationDevelopment/captureMarketIcon3.png";

const CaptureMarket = () => {
  return (
    <div className="my-14">
      <div className="container mx-auto text-center px-5 lg:px-20">
        <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
          SCALE AND CAPTURE MARKET SHARE
        </h1>
        <h1 className="lg:text-5xl text-3xl text-black md:text-3xl font-medium mt-2">
          Build SaaS application with us, lead the <br /> Competition
        </h1>
      </div>
      <div className="lg:flex items-center justify-around mt-16 px-10 lg:px-0">
        <div className="lg:translate-x-[60px]">
          <p className="text-base text-slate-500 leading-8">
            Take the right step forward and build a SaaS Application <br />{" "}
            development for your Business Idea with an experienced and tested{" "}
            <br /> team. Our Engineers have developed best practices over the
            years.
          </p>
          <p className="text-base text-slate-500 mt-4 leading-8">
            Building SaaS Apps around various ideas for different Industries,
            <br /> which sets us apart and makes the first choice of startups
            and
            <br />
            enterprises alike.
          </p>
          <div>
            <div className="flex items-center justify-center">
              <img className="w-32" src={CaptureIcon1} alt="" />
              <img className="w-32" src={CaptureIcon2} alt="" />
            </div>
            <div className="flex items-center justify-center">
              <img className="w-32" src={CaptureIcon3} alt="" />
              <img className="w-32" src={CaptureIcon2} alt="" />
              <img className="w-32" src={CaptureIcon2} alt="" />
            </div>
          </div>
        </div>
        <div className="lg:w-[41%] bg-gradient-to-r from-[#11278A] to-[#0071BB] p-6  w-full rounded-lg lg:translate-x-[80px] mt-8 lg:mt-0">
          <div className="pl-8 py-9">
            {" "}
            <div className="flex items-center mt-4">
              <img src={CaptureImg1} className="w-20 mr-4" alt="" />
              <div>
                <h1 className="font-bold text-white text-4xl lg:text-6xl">
                  94 %
                </h1>
                <p className="text-base text-white">Cloud-Native Solutions</p>
              </div>
            </div>
            <div className="flex items-center mt-4">
              <img src={CaptureImg1} className="w-20 mr-4" alt="" />
              <div>
                <h1 className="font-bold text-white text-4xl lg:text-6xl">
                  94 %
                </h1>
                <p className="text-base text-white">Cloud-Native Solutions</p>
              </div>
            </div>
            <div className="flex items-center mt-4">
              <img src={CaptureImg1} className="w-20 mr-4" alt="" />
              <div>
                <h1 className="font-bold text-white text-4xl lg:text-6xl">
                  94 %
                </h1>
                <p className="text-base text-white">Cloud-Native Solutions</p>
              </div>
            </div>
            <button className="btn rounded-full bg-transparent border-2 px-12 mt-8 hover:border-white hover:bg-transparent border-white">
              Get in Touch
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaptureMarket;
