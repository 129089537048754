import React from "react";
import Accelete from "../Home/Section/Accelarate/Accelete";
import DevOpsConsultingBanner from "./Section/DevOpsConsultingBanner/DevOpsConsultingBanner";
import DevOpsCouldService from "./Section/DevOpsCouldService/DevOpsCouldService";
import BestProvider from "./Section/BestProvider/BestProvider";
import ContactUs from "../SoftwareDevelopment/Section/ContactUs/ContactUs";
const DevOpsConsulting = () => {
  return (
    <div>
      <DevOpsConsultingBanner />
      <DevOpsCouldService />
      <BestProvider />
      <Accelete />
      <ContactUs />
    </div>
  );
};

export default DevOpsConsulting;
