import React from "react";
import Image from "../../../../../Images/caseStudio.png";
import Studio1 from "../../../../../Images/studio-1.png";
import Studio2 from "../../../../../Images/studio-2.jpg";
import Studio3 from "../../../../../Images/studio-3.jpg";

const CaseStudies = () => {
  return (
    <div>
      <div className="container mx-auto lg:px-20 px-8">
        <div className="text-center my-12">
          <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
            OUR CASE STUDIES
          </h1>
          <h1 className="lg:text-4xl text-2xl text-black md:text-3xl font-bold mt-4">
            Here’s How We Help Companies Scale
          </h1>
        </div>

        <div className="grid gap-0 lg:gap-10 lg:grid-cols-2 grid-cols-1">
          <div className="mt-6">
            <img src={Image} className="w-full" alt="" />
          </div>
          <div className="mt-6">
            <h1 className="text-lg font-bold text-sky-700">
              Staff Augmentation
            </h1>
            <h1 className="md:text-3xl text-2xl lg:text-4xl font-medium text-black">
              ArcusFi
            </h1>
            <br />
            <p className="text-lg">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum
              quis animi mollitia quam aliquam, eaque rerum magni odit veritatis
              libero, laudantium maxime saepe consectetur at? Et quo earum a
              voluptas aperiam molestias in tempora incidunt?
            </p>
            <br />
            <p className="text-lg">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim
              reprehenderit molestiae repudiandae maiores quam? Numquam deserunt
              blanditiis in minus quis omnis illum ex veniam.
            </p>
            <button className="btn rounded-full border-2 border-sky-700 bg-transparent text-sky-700 hover:bg-transparent hover:border-sky-700 px-16 mt-7 text-base ">
              Read the case
            </button>
          </div>
        </div>
      </div>

      <div className="bg-sky-100 p-5 mt-10 lg:mt-0">
        <div className="container mx-auto lg:px-20">
          <div className="mt-10 grid gap-0  grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center">
            <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-xl mt-10 lg:mt-0">
              <figure>
                <img src={Studio1} alt="Shoes" />
              </figure>
              <div className="p-4">
                <h1 className="text-lg font-bold text-sky-700">
                  <a href="">DEVOPS SERVICES</a>
                </h1>
                <h1 className="md:text-3xl text-2xl lg:text-4xl font-medium text-black mt-2">
                  <a href="">Fintech Company</a>
                </h1>
                <br />
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Aliquid, a? Provident ratione fugiat maiores quas consequatur
                  laboriosam aspernatur, culpa quos error consequuntur soluta
                  fuga libero aliquam in at atque optio quam autem accusantium
                  molestias ab!
                </p>
                <div className="text-center">
                  <button className="btn rounded-full border-2 border-sky-700 bg-transparent text-sky-700 hover:bg-transparent hover:border-sky-700 px-8 mt-7 text-base ">
                    Read the case
                  </button>
                </div>
              </div>
            </div>
            <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-xl mt-10 lg:mt-0">
              <figure>
                <img src={Studio2} alt="Shoes" />
              </figure>
              <div className="p-4">
                <h1 className="text-lg font-bold text-sky-700">
                  <a href="">DEDICATED TEAM</a>
                </h1>
                <h1 className="md:text-3xl text-2xl lg:text-4xl font-medium text-black mt-2">
                  <a href="">Rufus Labs</a>
                </h1>
                <br />
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Aliquid, a? Provident ratione fugiat maiores quas consequatur
                  laboriosam aspernatur, culpa quos error consequuntur soluta
                  fuga libero aliquam in at atque optio quam autem accusantium
                  molestias ab!
                </p>
                <div className="text-center">
                  <button className="btn rounded-full border-2 border-sky-700 bg-transparent text-sky-700 hover:bg-transparent hover:border-sky-700 px-8 mt-7 text-base ">
                    Read the case
                  </button>
                </div>
              </div>
            </div>
            <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-xl mt-10 lg:mt-0">
              <figure>
                <img src={Studio3} alt="Shoes" />
              </figure>
              <div className="p-4">
                <h1 className="text-lg font-bold text-sky-700">
                  <a href="">SOFTWARE DEVELOPMENT</a>
                </h1>
                <h1 className="md:text-3xl text-2xl lg:text-4xl font-medium text-black mt-2">
                  <a href="">BrandVerge</a>
                </h1>
                <br />
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Aliquid, a? Provident ratione fugiat maiores quas consequatur
                  laboriosam aspernatur, culpa quos error consequuntur soluta
                  fuga libero aliquam in at atque optio quam autem accusantium
                  molestias ab!
                </p>
                <div className="text-center">
                  <button className="btn rounded-full border-2 border-sky-700 bg-transparent text-sky-700 hover:bg-transparent hover:border-sky-700 px-8 mt-7 text-base ">
                    Read the case
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;
