import React from "react";
import willGetImg1 from "../../../../../Images/How-we-work/will-get-img-1.png";
import willGetImg2 from "../../../../../Images/How-we-work/will-get-img-2.png";
import willGetImg3 from "../../../../../Images/How-we-work/will-get-img-3.png";
import willGetImg4 from "../../../../../Images/How-we-work/will-get-img-4.png";
import willGetImg5 from "../../../../../Images/How-we-work/will-get-img-5.png";
import willGetImg6 from "../../../../../Images/How-we-work/will-get-img-6.png";
const YouWillGet = () => {
  return (
    <div className="container mx-auto lg:px-20 px-5">
      <div className="text-center mt-12 mb-10">
        <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
          WHAT YOU WILL GET
        </h1>
        <h1 className="lg:text-4xl text-2xl text-black md:text-3xl font-medium mt-4">
          How we work & the Benefits of working with us
        </h1>
      </div>
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 justify-center my-8">
        <div>
          <div className="flex">
            <div>
              <img src={willGetImg1} alt="" />
            </div>
            <div>
              <p className="text-lg ml-3 text-slate-600 leading-8">
                <b>Dedicated Team.</b> We can provide you a team of
                <br />
                experts that fit your needs and excellent problem
                <br />
                solvers that will add value to your organization.
              </p>
            </div>
          </div>
          <br />
          <div className="flex">
            <div>
              <img src={willGetImg2} alt="" />
            </div>
            <div>
              <p className="text-lg ml-3 text-slate-600 leading-8">
                <b>Daily Reporting (SCRUM).</b> We follow SCRUM
                <br />
                methodology for any project. We ensure a daily
                <br />
                communication workflow among parts.
              </p>
            </div>
          </div>
          <br />
          <div className="flex">
            <div>
              <img src={willGetImg3} alt="" />
            </div>
            <div>
              <p className="text-lg ml-3 text-slate-600 leading-8">
                <b>Same Timezone.</b> We ensure a time zone
                <br />
                alignment to deliver products faster, a real-time
                <br />
                collaboration and shorter feedback loops.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex">
            <div>
              <img src={willGetImg4} alt="" />
            </div>
            <div>
              <p className="text-lg ml-3 text-slate-600 leading-8">
                <b>Dedicated Team.</b> We can provide you a team of
                <br />
                experts that fit your needs and excellent problem
                <br />
                solvers that will add value to your organization.
              </p>
            </div>
          </div>
          <br />
          <div className="flex">
            <div>
              <img src={willGetImg5} alt="" />
            </div>
            <div>
              <p className="text-lg ml-3 text-slate-600 leading-8">
                <b>Daily Reporting (SCRUM).</b> We follow SCRUM
                <br />
                methodology for any project. We ensure a daily
                <br />
                communication workflow among parts.
              </p>
            </div>
          </div>
          <br />
          <div className="flex">
            <div>
              <img src={willGetImg6} alt="" />
            </div>
            <div>
              <p className="text-lg ml-3 text-slate-600 leading-8">
                <b>Same Timezone.</b> We ensure a time zone
                <br />
                alignment to deliver products faster, a real-time
                <br />
                collaboration and shorter feedback loops.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouWillGet;
