import React from "react";

const Characteristics = () => {
  return (
    <div className="container mx-auto lg:px-20 px-5 my-8">
      <div>
        <h1 className="lg:text-4xl text-3xl font-bold text-black text-center">
          What are the key characteristics of an ‘A’ player?
        </h1>
        <div className="flex justify-evenly mt-10">
          <div>
            <div className="flex items-center">
              <i class="fa-regular fa-circle-check text-lg text-sky-600 mr-3"></i>{" "}
              <p className="text-lg text-slate-500">Critical thinking.</p>
            </div>
            <div className="flex items-center mt-2">
              <i class="fa-regular fa-circle-check text-lg text-sky-600 mr-3"></i>{" "}
              <p className="text-lg text-slate-500">
                Entrepreneurship mindset.
              </p>
            </div>
            <div className="flex items-center mt-2">
              <i class="fa-regular fa-circle-check text-lg text-sky-600 mr-3"></i>{" "}
              <p className="text-lg text-slate-500">Proactive.</p>
            </div>
            <div className="flex items-center mt-2">
              <i class="fa-regular fa-circle-check text-lg text-sky-600 mr-3"></i>{" "}
              <p className="text-lg text-slate-500">Highly communicative.</p>
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <i class="fa-regular fa-circle-check text-lg text-sky-600 mr-3"></i>{" "}
              <p className="text-lg text-slate-500">Attention to detail</p>
            </div>
            <div className="flex items-center mt-2">
              <i class="fa-regular fa-circle-check text-lg text-sky-600 mr-3"></i>{" "}
              <p className="text-lg text-slate-500">Autonomous.</p>
            </div>
            <div className="flex items-center mt-2">
              <i class="fa-regular fa-circle-check text-lg text-sky-600 mr-3"></i>{" "}
              <p className="text-lg text-slate-500">Fully accountable.</p>
            </div>
            <div className="flex items-center mt-2">
              <i class="fa-regular fa-circle-check text-lg text-sky-600 mr-3"></i>{" "}
              <p className="text-lg text-slate-500">
                Embedded to custome's values & <br />
                business goals.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 mt-10">
        <div>
          <h1 className="font-bold lg:text-4xl text-3xl">
            Get more benefits <br />
            with a Nearshore
            <br /> Team
          </h1>
          <div className="flex items-center mt-5">
            <i class="fa-regular fa-circle-check text-lg text-sky-600 mr-3"></i>{" "}
            <p className="text-lg text-slate-700">
              <b>Awesome Team:</b> We share the same culture thus <br />
              we align with your team procedures.
            </p>
          </div>
          <div className="flex items-center mt-5">
            <i class="fa-regular fa-circle-check text-lg text-sky-600 mr-3"></i>{" "}
            <p className="text-lg text-slate-700">
              <b>Support in your Timezone:</b> Stop having <br />
              early/nightly meetings. Work only in business hours.
            </p>
          </div>
          <div className="flex items-center mt-5">
            <i class="fa-regular fa-circle-check text-lg text-sky-600 mr-3"></i>{" "}
            <p className="text-lg text-slate-700">
              <b>A Team of Experts:</b> Real IT experts for your
              <br />
              business.
            </p>
          </div>
        </div>
        <div className="shadow-2xl p-8  lg:flex justify-center rounded-2xl items-center">
          <form action="" className="mt-10px">
            <div className="lg:flex md:flex items-center">
              <div className="lg:mr-7 md:mr-7 mb-5 lg:mb-0">
                <label className="text-sm text-slate-400 pb-2" htmlFor="">
                  First name<sup className="text-red-500">*</sup>
                </label>
                <br />
                <input
                  placeholder="Your Name"
                  className="input w-full border-slate-600  rounded-2xl h-9 bg-sky-100"
                  type="text"
                />
              </div>
              <div>
                <label className="text-sm text-slate-400 pb-2" htmlFor="">
                  Last name<sup className="text-red-500">*</sup>
                </label>
                <br />
                <input
                  placeholder="Your Last Name"
                  className="input w-full border-slate-600  rounded-2xl h-9 bg-sky-100"
                  type="text"
                />
              </div>
            </div>
            <br />
            <div>
              <label className="text-sm text-slate-400 pb-2" htmlFor="">
                Email<sup className="text-red-500">*</sup>
              </label>
              <br />
              <input
                placeholder="example@gmail.com"
                type="text"
                className="input border-slate-600  rounded-2xl h-9 lg:w-[460px] md:w-[460px] w-full bg-sky-100"
              />
            </div>
            <br />
            <div className="lg:flex md:flex items-center">
              <div className="lg:mr-7 md:mr-7 mb-5 lg:mb-0">
                <label className="text-sm text-slate-400 pb-2" htmlFor="">
                  Company name<sup className="text-red-500">*</sup>
                </label>
                <br />
                <input
                  placeholder="Your Company"
                  className="input w-full border-slate-600  rounded-2xl h-9 bg-sky-100"
                  type="text"
                />
              </div>
              <div>
                <label className="text-sm text-slate-400 pb-2" htmlFor="">
                  Phone name<sup className="text-red-500">*</sup>
                </label>
                <br />
                <input
                  placeholder="000 000 000"
                  className="input w-full border-slate-600  rounded-2xl h-9 bg-sky-100"
                  type="text"
                />
              </div>
            </div>
            <div className="mt-5">
              <button className="btn rounded-full  bg-[#ff6700] hover:bg-[#ff6700] border-[#ff6700] hover:border-[#ff6700]">
                Contact Us
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Characteristics;
