import React from "react";
import "./AwsServiceBanner.css";

const AwsServiceBanner = () => {
  return (
    <div className="awaService-container pt-20 lg:pt-24">
      <div className="container mx-auto px-5 lg:px-8">
        <div className="grid gap-0 lg:gap-10 grid-cols-1 lg:grid-cols-2 items-center justify-items-center py-14">
          <div className="text-white mt-8 lg:mt-0">
            <h1 className="lg:text-6xl text-4xl font-bold">
              AWS Managed
              <br /> Services
            </h1>
            <p className="text-2xl font-bold mt-5">
              We run, automate & optimize applications
              <br />
              on AWS
            </p>
          </div>
          <div className="shadow-2xl p-8 w-full  lg:flex justify-center rounded-2xl items-center bg-gradient-to-r from-slate-600 to-transparent">
            <form action="" className="mt-10px">
              <div className="lg:flex md:flex items-center">
                <div className="lg:mr-7 md:mr-7 mb-5 lg:mb-0">
                  <label className="text-sm text-slate-400 pb-2" htmlFor="">
                    First name<sup className="text-red-500">*</sup>
                  </label>
                  <br />
                  <input
                    placeholder="Your Name"
                    className="input w-full border-slate-600  rounded-2xl h-9 bg-sky-100"
                    type="text"
                  />
                </div>
                <div>
                  <label className="text-sm text-slate-400 pb-2" htmlFor="">
                    Last name<sup className="text-red-500">*</sup>
                  </label>
                  <br />
                  <input
                    placeholder="Your Last Name"
                    className="input w-full border-slate-600  rounded-2xl h-9 bg-sky-100"
                    type="text"
                  />
                </div>
              </div>
              <br />
              <div>
                <label className="text-sm text-slate-400 pb-2" htmlFor="">
                  Email<sup className="text-red-500">*</sup>
                </label>
                <br />
                <input
                  placeholder="example@gmail.com"
                  type="text"
                  className="input border-slate-600  rounded-2xl h-9 lg:w-[460px] md:w-[460px] w-full bg-sky-100"
                />
              </div>
              <br />
              <div className="lg:flex md:flex items-center">
                <div className="lg:mr-7 md:mr-7 mb-5 lg:mb-0">
                  <label className="text-sm text-slate-400 pb-2" htmlFor="">
                    Company name<sup className="text-red-500">*</sup>
                  </label>
                  <br />
                  <input
                    placeholder="Your Company"
                    className="input w-full border-slate-600  rounded-2xl h-9 bg-sky-100"
                    type="text"
                  />
                </div>
                <div>
                  <label className="text-sm text-slate-400 pb-2" htmlFor="">
                    Phone name<sup className="text-red-500">*</sup>
                  </label>
                  <br />
                  <input
                    placeholder="000 000 000"
                    className="input w-full border-slate-600  rounded-2xl h-9 bg-sky-100"
                    type="text"
                  />
                </div>
              </div>
              <div className="mt-5">
                <button className="btn rounded-full  bg-[#ff6700] hover:bg-[#ff6700] border-[#ff6700] hover:border-[#ff6700]">
                  Contact Us
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwsServiceBanner;
