import React from "react";
import BannerImg from "../../../../../Images/sassApplicationDevelopment/BannerImg.png";

const SaasDevelopmentBanner = () => {
  return (
    <div className="bg-gradient-to-r from-[#11278A] to-[#0071BB] lg:pt-24 mt-20 pb-10">
      <div className="container mx-auto lg:px-20 px-5">
        <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center ">
          <div className="flex justify-center mt-10 lg:mt-0">
            <img className="w-80" src={BannerImg} alt="" />
          </div>
          <div className="mt-10 lg:mt-0 text-white">
            <h1 className="text-xl font-medium mb-3">
              BETTER WAY OF BUILDING A
            </h1>
            <h1 className="lg:text-5xl text-4xl font-bold ">
              SaaS Application
            </h1>
            <p className="text-lg mt-6  ">
              SaaS Application Development service helps you cut the layers and{" "}
              <br /> effective unproductive mistakes out of your process.
            </p>
            <p className="text-lg mt-4 ">
              Launch your app in time & capture the market share that your idea
              deserves. SCHEDULE A CALL
            </p>
            <button className="btn rounded-full border-2 border-white bg-transparent text-white hover:bg-transparent hover:border-white px-12 mt-7 text-base ">
              SCHEDULE A CALL
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaasDevelopmentBanner;
