import React from "react";
import AllInOneAws from "./Section/AllInOneAws/AllInOneAws";
import AksedQuestion from "./Section/AskedQuestion/AksedQuestion";
import AwsServiceBanner from "./Section/AwaServiceBanner/AwsServiceBanner";
import AwsService from "./Section/AwsService/AwsService";
import BlogPosts from "./Section/BlogPosts/BlogPosts";
import FullSupportAws from "./Section/FullSupportAws/FullSupportAws";
import HowAwsWorks from "./Section/HowAwsWorks/HowAwsWorks";
import HowWeHelp from "./Section/HowWeHelp/HowWeHelp";
import ManageAwsCould from "./Section/ManageAwsCould/ManageAwsCould";

const AwsManageService = () => {
  return (
    <div>
      <AwsServiceBanner />
      <ManageAwsCould />
      <AwsService />
      <FullSupportAws />
      <HowAwsWorks />
      <AllInOneAws />
      <HowWeHelp />
      <BlogPosts />
      <AksedQuestion />
    </div>
  );
};

export default AwsManageService;
