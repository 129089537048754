import React from "react";
import aboutAchive from "../../../../../Images/about/About-achive.png";
import Certified from "../../../../../Images/about/about-certified.png";
import sideIcon from "../../../../../Images/about/aboutSideIcon.png";
import sideIconShort from "../../../../../Images/about/sidebarShortIcon.png";
import FakeUser1 from "../../../../../Images/about/fakeUser.jpg";
import FakeUser2 from "../../../../../Images/about/fakeUser2.jpg";
import FakeUser3 from "../../../../../Images/about/fakeUser3.jpg";
import FakeUser4 from "../../../../../Images/about/fakeUser4.jpg";
import FakeUser5 from "../../../../../Images/about/fakeUser5.jpg";
import FakeUser6 from "../../../../../Images/about/fakeUser6.jpg";
import ContactImg from "../../../../../Images/about/contact-img.png";
import "./AboutUsDescription.css";

const AboutUsDescription = () => {
  return (
    <div>
      <div className="container mx-auto lg:px-20 px-5">
        <div className="text-center lg:my-12 mt-6 lg:mt-12">
          <h1 className="md:text-lg lg:text-2xl font-bold text-sky-700">
            ABOUT US
          </h1>
          <h1 className="lg:text-4xl text-black md:text-3xl font-bold mt-4">
            A Bangladeshi IT and Software Outsourcing Company
          </h1>
        </div>
        <div className="grid gap-0 lg:gap-12 grid-cols-1 lg:grid-cols-2 justify-items-center ">
          <div className="my-8 lg:my-0">
            <div>
              <h1 className="text-2xl font-medium text-sky-700">
                5 Years in Market
              </h1>
              <p className="text-lg">
                DevelopersThrill has over 5 years in the IT market,
                <br /> providing top-notch solutions to all-sized
                <br /> companies around the world.
              </p>
            </div>
            <br />
            <div>
              <h1 className="text-2xl font-medium text-sky-700">
                20+ Certified Engineers
              </h1>
              <p className="text-lg">
                As a service provider of DevOps and Software
                <br /> Development, we’re committed to deliver high
                <br />
                quality solutions for your Applications.
              </p>
            </div>
            <br />
            <div>
              <h1 className="text-2xl font-medium text-sky-700">
                300+ Successful Projects
              </h1>
              <p className="text-lg">
                We have build, deliver and operate more than 300
                <br />
                successful projects in the Cloud regarding DevOps
                <br /> and Software Development operations.
              </p>
            </div>
            <br />{" "}
            <div>
              <h1 className="text-2xl font-medium text-sky-700">
                100% Cloud-native Solutions
              </h1>
              <p className="text-lg">
                Our approach consists on optimizing systems in
                <br />
                the cloud, pairing the cloud flexibility and on-
                <br />
                demand infrastructure with cloud technologies like.
              </p>
            </div>
          </div>
          <div className="flex justify-between mb-8 lg:mb-0">
            <div className="translate-x-[-62px] hidden lg:block">
              <img src={sideIcon} className="w-14 h-44 " alt="" />
              <img
                src={sideIcon}
                className="w-14 h-44 translate-y-[-30px]"
                alt=""
              />
              <img
                src={sideIcon}
                className="w-14 h-44 translate-y-[-58px]"
                alt=""
              />
              <img
                src={sideIconShort}
                className="w-14  translate-y-[-76px]"
                alt=""
              />
            </div>
            <div>
              <div className="flex justify-center ">
                <img src={aboutAchive} alt="" />
              </div>
              <div className="flex items-center justify-center ">
                <img className="w-32" src={Certified} alt="" />
                <img className="w-32" src={Certified} alt="" />
                <img className="w-32" src={Certified} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="location-bg py-14">
        <div className="container mx-auto lg:px-20 px-5">
          <div className="grid gap-0 lg:gap-12 grid-cols-1 lg:grid-cols-2">
            <div className="w-96"></div>
            <div className="flex justify-center">
              <div>
                <div className="lg:w-72 w-full rounded-xl bg-[#11278A] p-6 text-white">
                  <h1 className="text-3xl font-medium">
                    <a href="">Los Angeles</a>
                  </h1>
                  <p className="text-sm text-slate-300 mt-3">
                    468 N Camden Dr, Beverly Hills, Los Angeles, CA 90210, USA.
                  </p>
                  <p className="mt-3">
                    <a className="text-blue-500 hover:text-[#ff6700]" href="">
                      Show map
                    </a>
                  </p>
                </div>
                <br />
                <div className="lg:w-72 w-full rounded-xl bg-[#11278A] p-6 text-white">
                  <h1 className="text-3xl font-medium">
                    <a href="">Los Angeles</a>
                  </h1>
                  <p className="text-sm text-slate-300 mt-3">
                    468 N Camden Dr, Beverly Hills, Los Angeles, CA 90210, USA.
                  </p>
                  <p className="mt-3">
                    <a className="text-blue-500 hover:text-[#ff6700]" href="">
                      Show map
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" container mx-auto lg:px-20 px-5 text-center my-16">
        <div>
          <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
            WHO IS DevelopersThrill?
          </h1>
          <h1 className="lg:text-4xl text-2xl text-black md:text-3xl font-bold mt-4">
            A Nearshore DevOps & Software
            <br />
            Development Company
          </h1>
          <p className="text-lg leading-8 text-slate-600	mt-6">
            We help startups, SaaS, and enterprise companies with cutting-edge
            solutions. As an AWS select Partner, we provide holistic solutions
            that
            <br />
            integrate the analysis, consultancy, design, and implementation of
            custom cloud projects. Our Clients range from Inc 5000 global
            <br />
            enterprises to well-funded startups. Worldwide, DevelopersThrill
            helps IT companies implement DevOps and build cloud applications
            through a
            <br />
            remote nearshore collaboration model.
          </p>
        </div>
        <div>
          <h1 className="md:text-lg text-3xl lg:text-4xl font-bold mt-8 text-black">
            Our core team
          </h1>
          <div className="grid gap-0 justify-center grid-cols-1 lg:grid-cols-3 md:grid-cols-2 my-8">
            <div class="card lg:w-80  w-full bg-base-100 shadow-xl py-4 mt-6">
              <div className="flex justify-center">
                <img
                  src={FakeUser1}
                  alt="Shoes"
                  class="w-52 h-52 rounded-full"
                />
              </div>

              <div class=" text-center my-4">
                <h2 class="font-bold text-blue-900 text-2xl">Alfonso Valdes</h2>
                <p className="font-medium text-xl text-slate-400 mt-2">
                  Chief Executive Officer (CEO)
                </p>
                <div>
                  <ul className="flex items-center justify-center mt-6">
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-facebook text-xl"></i>
                      </a>
                    </li>
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-twitter text-xl"></i>
                      </a>
                    </li>

                    <li className="">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-linkedin text-xl"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card lg:w-80  w-full bg-base-100 shadow-xl py-4 mt-6">
              <div className="flex justify-center">
                <img
                  src={FakeUser2}
                  alt="Shoes"
                  class="w-52 h-52 rounded-full"
                />
              </div>

              <div class=" text-center my-4">
                <h2 class="font-bold text-blue-900 text-2xl">Juan Jose</h2>
                <p className="font-medium text-xl text-slate-400 mt-2">
                  VP of Engineering
                </p>
                <div>
                  <ul className="flex items-center justify-center mt-6">
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-facebook text-xl"></i>
                      </a>
                    </li>
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-twitter text-xl"></i>
                      </a>
                    </li>

                    <li className="">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-linkedin text-xl"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card lg:w-80  w-full bg-base-100 shadow-xl py-4 mt-6">
              <div className="flex justify-center">
                <img
                  src={FakeUser3}
                  alt="Shoes"
                  class="w-52 h-52 rounded-full"
                />
              </div>

              <div class=" text-center my-4">
                <h2 class="font-bold text-blue-900 text-2xl">Ana Isabel</h2>
                <p className="font-medium text-xl text-slate-400 mt-2">
                  Human Resources (HR)
                </p>
                <div>
                  <ul className="flex items-center justify-center mt-6">
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-facebook text-xl"></i>
                      </a>
                    </li>
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-twitter text-xl"></i>
                      </a>
                    </li>

                    <li className="">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-linkedin text-xl"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card lg:w-80  w-full bg-base-100 shadow-xl py-4 mt-6">
              <div className="flex justify-center">
                <img
                  src={FakeUser4}
                  alt="Shoes"
                  class="w-52 h-52 rounded-full"
                />
              </div>

              <div class=" text-center my-4">
                <h2 class="font-bold text-blue-900 text-2xl">
                  Agustin Espinoza
                </h2>
                <p className="font-medium text-xl text-slate-400 mt-2">
                  Senior Development Lead
                </p>
                <div>
                  <ul className="flex items-center justify-center mt-6">
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-facebook text-xl"></i>
                      </a>
                    </li>
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-twitter text-xl"></i>
                      </a>
                    </li>

                    <li className="">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-linkedin text-xl"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card lg:w-80  w-full bg-base-100 shadow-xl py-4 mt-6">
              <div className="flex justify-center">
                <img
                  src={FakeUser5}
                  alt="Shoes"
                  class="w-52 h-52 rounded-full"
                />
              </div>

              <div class=" text-center my-4">
                <h2 class="font-bold text-blue-900 text-2xl">Ana Karen</h2>
                <p className="font-medium text-xl text-slate-400 mt-2">
                  Marketing Manager
                </p>
                <div>
                  <ul className="flex items-center justify-center mt-6">
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-facebook text-xl"></i>
                      </a>
                    </li>
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-twitter text-xl"></i>
                      </a>
                    </li>

                    <li className="">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-linkedin text-xl"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card lg:w-80  w-full bg-base-100 shadow-xl py-4 mt-6">
              <div className="flex justify-center">
                <img
                  src={FakeUser6}
                  alt="Shoes"
                  class="w-52 h-52 rounded-full"
                />
              </div>

              <div class=" text-center my-4">
                <h2 class="font-bold text-blue-900 text-2xl">Hector Aguirre</h2>
                <p className="font-medium text-xl text-slate-400 mt-2">
                  Senior DevOps Lead
                </p>
                <div>
                  <ul className="flex items-center justify-center mt-6">
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-facebook text-xl"></i>
                      </a>
                    </li>
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-twitter text-xl"></i>
                      </a>
                    </li>

                    <li className="">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-linkedin text-xl"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto lg:px-20 px-6 my-10">
        <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center">
          <div>
            <img src={ContactImg} alt="" />
          </div>
          <div className=" mt-6 lg:mt-0">
            <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
              OUR CASE STUDIES
            </h1>
            <h1 className="lg:text-4xl text-2xl text-black md:text-2xl font-medium mt-4">
              Here’s How We Help Companies
            </h1>
            <p className="text-lg mt-6 text-slate-600">
              Nearshore with DevelopersThrill is the key to scaling your
              enterprise up. We
              <br />
              are a team of expert solvers that provide continuous
              <br />
              communication in a similar time zone with our customers.
            </p>
            <button className="btn rounded-full bg-transparent border-4 px-12 mt-6 hover:border-sky-600 hover:bg-transparent border-sky-600 text-blue-700">
              Contact Us!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsDescription;
