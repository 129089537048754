import React from "react";
import BestProviderImg from "../../../../../Images/DevOpsConsulting/bestProviderImg.png";

const BestProvider = () => {
  return (
    <div className="container mx-auto lg:px-20 px-5 lg:mb-0 mb-8">
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 justify-items-center items-center">
        <div>
          <img src={BestProviderImg} alt="" />
        </div>
        <div>
          <h1 className="md:text-lg lg:text-2xl font-bold text-sky-700">
            BEST DEVOPS PROVIDERS
          </h1>
          <h1 className="lg:text-5xl text-black md:text-3xl font-medium mt-2">
            Top DevOps Consulting <br />
            Companies
          </h1>
          <p className="text-lg text-slate-600 mt-5">
            Improve your software development process with the best
            <br />
            DevOps Consulting company. Let our team of expert engineers
            <br />
            on AWS DevOps Consulting to managing your unique Cloud <br />
            Infrastructure.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BestProvider;
