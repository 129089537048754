import React from "react";
import "./AboutBanner.css";

const AboutBanner = () => {
  return (
    <div className="about-BG text-white text-center">
      <div className="container mx-auto lg:px-20 px-5 py-32 mt-14">
        <h1 className="lg:text-5xl md:text-4xl text-3xl font-medium">
          Our Tech Vision, Your Future
        </h1>
        <p className="text-2xl mt-4">Innovation through Nearshore Talent</p>
        <button className="btn rounded-full bg-transparent border-4 px-12 mt-10 hover:border-[#ff6700] hover:bg-transparent border-[#ff6700]">
          Contact us!
        </button>
      </div>
    </div>
  );
};

export default AboutBanner;
