import React from "react";
import "./SkillSoftwareDev.css";
import skillSoftwareImg from "../../../../../Images/softwareDevelopment/skillSoftwareDeveloper.png";
const SkillSoftwareDev = () => {
  return (
    <div className="container  mx-auto px-5 lg:px-20 my-8">
      <div className="container mx-auto rounded-xl skillSoftware-container p-4">
        <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 justify-items-center items-center">
          <div className="text-white">
            {" "}
            <h1 className="lg:text-3xl text-3xl font-medium text-white">
              SKILLED SOFTWARE DEVELOPERS​
            </h1>
            <p className="text-lg mt-2">Your ideal partner for outsourcing! </p>
            <p className="text-lg mt-2">
              We are a highly qualified team of software developers. We’ve been{" "}
              <br /> globally recognized for working with top-notch technologies
              such
              <br />
              as Phyton, React, Node.js, and Laravel, while delivering agile
              <br />
              custom software development.{" "}
            </p>
            <button className="btn rounded-full bg-transparent border-2 px-12 mt-6 bg-[#ff6700] hover:border-[#ff6700] hover:bg-transparent border-[#ff6700]">
              Contact us!
            </button>
          </div>
          <div className="mt-5 lg:mt-0">
            <img src={skillSoftwareImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillSoftwareDev;
