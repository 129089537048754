import React from "react";
import outsourcingServiceImg1 from "../../../../../Images/DebOpsOutsourcing/outsourcingService1.png";
import outsourcingServiceImg2 from "../../../../../Images/DebOpsOutsourcing/outsourcingService2.png";
import outsourcingServiceImg3 from "../../../../../Images/DebOpsOutsourcing/outsourcingService3.png";
import outsourcingServiceImg4 from "../../../../../Images/DebOpsOutsourcing/outsourcingService4.png";
import outsourcingServiceImg5 from "../../../../../Images/DebOpsOutsourcing/outsourcingService5.png";
import outsourcingServiceImg6 from "../../../../../Images/DebOpsOutsourcing/outsourcingService6.png";
const OutsourceService = () => {
  return (
    <div className="container mx-auto px-5 lg:px-20">
      <div className="text-center lg:my-12 mt-6 lg:mt-12">
        <h1 className="md:text-lg lg:text-2xl font-bold text-sky-700">
          OUTSOURCE DEVOPS
        </h1>
        <h1 className="lg:text-4xl text-black md:text-3xl font-bold mt-2">
          Our DevOps Outsourcing Services include:
        </h1>
      </div>
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 mt-4 items-center justify-items-center mb-8">
        <div>
          <div className="flex items-center border-sky-400 border p-4 shadow-lg hover:shadow-2xl mt-4 rounded-xl">
            <img className="w-28 mr-3" src={outsourcingServiceImg1} alt="" />
            <div>
              <h1 className="text-xl font-medium">
                <a className="text-blue-700 hover:text-[#ff6700]" href="">
                  Kubernetes Clustering
                </a>
              </h1>
              <p className="text-slate-500">
                Make the potential of containers an operational
                <br />
                reality by running containerized applications across <br />
                multiple machines and environments.
              </p>
            </div>
          </div>
          <div className="flex items-center border-sky-400 border p-4 shadow-lg hover:shadow-2xl mt-4 rounded-xl">
            <img className="w-28 mr-3" src={outsourcingServiceImg2} alt="" />
            <div>
              <h1 className="text-xl font-medium">
                <a className="text-blue-700 hover:text-[#ff6700]" href="">
                  Microservice Architecture
                </a>
              </h1>
              <p className="text-slate-500">
                Evolve the tech stack of a complex application with a
                <br />
                microservice architecture while enabling a rapid,
                <br />
                frequent and reliable delivery.
              </p>
            </div>
          </div>
          <div className="flex items-center border-sky-400 border p-4 shadow-lg hover:shadow-2xl mt-4 rounded-xl">
            <img className="w-28 mr-3" src={outsourcingServiceImg3} alt="" />
            <div>
              <h1 className="text-xl font-medium">
                <a className="text-blue-700 hover:text-[#ff6700]" href="">
                  Infrastructure as Code
                </a>
              </h1>
              <p className="text-slate-500">
                Provision and manage cloud resources by writing a
                <br />
                template that is human readable and machine <br />
                consumable.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex items-center border-sky-400 border p-4 shadow-lg hover:shadow-2xl mt-4 rounded-xl">
            <img className="w-28 mr-3" src={outsourcingServiceImg4} alt="" />
            <div>
              <h1 className="text-xl font-medium">
                <a className="text-blue-700 hover:text-[#ff6700]" href="">
                  Set up CI/CD Pipelines
                </a>
              </h1>
              <p className="text-slate-500">
                Design and build continuous integration & delivery
                <br />
                pipelines to automate your software delivery <br />
                process through AWS CodePipeline.
              </p>
            </div>
          </div>
          <div className="flex items-center border-sky-400 border p-4 shadow-lg hover:shadow-2xl mt-4 rounded-xl">
            <img className="w-28 mr-3" src={outsourcingServiceImg5} alt="" />
            <div>
              <h1 className="text-xl font-medium">
                <a className="text-blue-700 hover:text-[#ff6700]" href="">
                  Serverless architecture​
                </a>
              </h1>
              <p className="text-slate-500">
                Build and run applications without managing the
                <br />
                infrastructure, running apps on servers, but with no
                <br />
                need for provisioning, scaling, and maintenance.
              </p>
            </div>
          </div>
          <div className="flex items-center border-sky-400 border p-4 shadow-lg hover:shadow-2xl mt-4 rounded-xl">
            <img className="w-28 mr-3" src={outsourcingServiceImg6} alt="" />
            <div>
              <h1 className="text-xl font-medium">
                <a className="text-blue-700 hover:text-[#ff6700]" href="">
                  DevSecOps
                </a>
              </h1>
              <p className="text-slate-500">
                Integrate security practices on the Software
                <br />
                Development Lifecycle to ensure security .<br />
                consumable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutsourceService;
