import React from "react";

// import { Parallax } from 'react-parallax';

import "./Banner.css";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" bg-img1 w-full mx-auto ">
        {/* <div className="hero-overlay bg-opacity-30"></div> */}
        <div className="flex items-center text-center lg:text-left container text-neutral-content px-6 lg:px-28 py-10 mt-14">
          <div
            data-aos="fade-up"
            data-aos-duration="3000"
            data-aos-anchor-placement="center-bottom"
          >
            <h1
              style={{
                fontFamily: "Montserrat, Sans-serif",
                textShadow: "0px 1px 2px black",
                lineHeight: "120%",
                marginTop: "30px",
              }}
              className="mb-5 text-white md:text-6xl lg:text-6xl text-4xl font-bold"
            >
              IT Experts for Fast-
              <br /> Growing Companies
            </h1>
            <p
              style={{ fontFamily: "Montserrat, Sans-serif" }}
              className="mx-auto mt-4 mb-4 text-white font-normal  md:text-lg lg:text-2xl max-w-[40rem]"
            >
              Scale Your Business with Nearshore DevOps, Software Development,
              and Staff Augmentation Services.
            </p>
            <button
              style={{ fontFamily: "Montserrat, Sans-serif" }}
              className="text-color  rounded-full border-base-300 px-8 py-2 mt-4 duration-500 btn-bg mt-16"
            >
              Schedule an Exploratory Call
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
