import React from "react";
import Img from "../../../../../Images/AWSManageService/AllInOneImg.png";
const AllInOneAws = () => {
  return (
    <div className="container mx-auto px-5 lg:px-20 my-14">
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center justify-items-center">
        <div>
          <h1 className="text-3xl lg:text-4xl font-medium text-black">
            Your All-In-One AWS <br />
            Managed Services Partner
          </h1>
          <p className="text-slate-600 text-lg leading-8 mt-4">
            We provide end-to-end services for companies building critical cloud{" "}
            <br />
            solutions using Amazon Web Services. Our deep expertise on <br />
            the <b>AWS Cloud</b>, automated blueprints and processes means that
            we <br />
            provide the <b>fastest time to value on AWS </b> with 24×7
            operational <br />
            management.
          </p>
          <button className="btn rounded-full bg-transparent border-2 px-12 lg:mt-8 hover:border-500 hover:bg-transparent border-sky-500 text-sky-600">
            Contact us
          </button>
        </div>
        <div>
          <img src={Img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default AllInOneAws;
