import React, { useEffect, useState } from "react";
import Img1 from "../../../../../Images/AWSManageService/fullSupportImg1.png";
import Img2 from "../../../../../Images/AWSManageService/fullSupportImg2.png";
import Img3 from "../../../../../Images/AWSManageService/fullSupportImg3.png";
import Img4 from "../../../../../Images/AWSManageService/fullSupportImg4.png";
import Img5 from "../../../../../Images/AWSManageService/fullSupportImg5.png";
import Img6 from "../../../../../Images/AWSManageService/fullSupportImg6.png";
import "./FullSupportAws.css";
const FullSupportAws = () => {
  const [showSupport, setShowSupport] = useState("devops");
  const [showImg, setShowImg] = useState(Img1);

  useEffect(() => {
    if (showSupport === "devops") {
      setShowImg(Img1);
    } else if (showSupport === "serverless") {
      setShowImg(Img2);
    } else if (showSupport === "databases") {
      setShowImg(Img3);
    } else if (showSupport === "deploy") {
      setShowImg(Img4);
    } else if (showSupport === "networking") {
      setShowImg(Img5);
    } else if (showSupport === "app-stack") {
      setShowImg(Img6);
    } else {
      setShowImg(Img1);
    }
  }, [showSupport]);
  return (
    <div className="container mx-auto px-5 lg:px-20 my-14">
      <div className="lg:flex justify-around">
        <div className="text-center lg:text-left">
          <h1 className="lg:text-4xl text-3xl font-medium leading-9 ">
            Full Support <br />
            on AWS
          </h1>
          <p className="text-lg text-slate-600 leading-9 mt-4">
            DevelopersThrill provides a wide range of AWS <br /> fully managed
            services for premium
            <br />
            cloud support.
          </p>
        </div>
        <div>
          <ul className="lg:flex items-center justify-center text-center mt-4 lg:mt-0 mb-5">
            <div className="flex items-center justify-center">
              <li>
                <button
                  onClick={() => setShowSupport("devops")}
                  className={`btn rounded-full bg-transparent border-none   px-4 hover:text-white duration-300 lg:mt-8 hover:bg-transparent hover:bg-sky-400 btn-sm ${
                    showSupport === "devops"
                      ? "bg-sky-400 text-white opaque"
                      : "text-slate-500"
                  }`}
                >
                  DEVOPS
                </button>
              </li>
              <li>
                <button
                  onClick={() => setShowSupport("serverless")}
                  className={`btn rounded-full bg-transparent border-none   px-4 hover:text-white duration-300 lg:mt-8 hover:bg-transparent hover:bg-sky-400 btn-sm ${
                    showSupport === "serverless"
                      ? "bg-sky-400 text-white opaque"
                      : "text-slate-500"
                  }`}
                >
                  SERVERLESS
                </button>
              </li>
              <li>
                <button
                  onClick={() => setShowSupport("databases")}
                  className={`btn rounded-full bg-transparent border-none   px-4 hover:text-white duration-300 lg:mt-8 hover:bg-transparent hover:bg-sky-400 btn-sm ${
                    showSupport === "databases"
                      ? "bg-sky-400 text-white opaque"
                      : "text-slate-500"
                  }`}
                >
                  DATABASES
                </button>
              </li>
              <li>
                <button
                  onClick={() => setShowSupport("deploy")}
                  className={`btn rounded-full bg-transparent border-none   px-4 hover:text-white duration-300 lg:mt-8 hover:bg-transparent hover:bg-sky-400 btn-sm ${
                    showSupport === "deploy"
                      ? "bg-sky-400 text-white opaque"
                      : "text-slate-500"
                  }`}
                >
                  DEPLOY
                </button>
              </li>
            </div>
            <div className="flex items-center justify-center mt-3 lg:mt-0">
              <li>
                <button
                  onClick={() => setShowSupport("networking")}
                  className={`btn rounded-full bg-transparent border-none   px-4 hover:text-white duration-300 lg:mt-8 hover:bg-transparent hover:bg-sky-400 btn-sm ${
                    showSupport === "networking"
                      ? "bg-sky-400 text-white opaque"
                      : "text-slate-500"
                  }`}
                >
                  NETWORKING
                </button>
              </li>
              <li>
                <button
                  onClick={() => setShowSupport("app-stack")}
                  className={`btn rounded-full bg-transparent border-none   px-4 hover:text-white duration-300 lg:mt-8 hover:bg-transparent hover:bg-sky-400 btn-sm ${
                    showSupport === "app-stack"
                      ? "bg-sky-400 text-white "
                      : "text-slate-500"
                  }`}
                >
                  APP STACK
                </button>
              </li>
            </div>
          </ul>
          <figure className="group relative">
            <img classN src={showImg} className="w-full " />
            <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-white opacity-0 group-hover:h-full group-hover:opacity-70 duration-500">
              <h1 className="text-center  text-sky-600 text-2xl">App Stack</h1>
            </div>
          </figure>
        </div>
      </div>
    </div>
  );
};

export default FullSupportAws;
