import React from "react";
import TeamHelpedImg1 from "../../../../../Images/How-we-work/helpedTeam1.jpg";
import TeamHelpedImg2 from "../../../../../Images/How-we-work/helpedTeam2.jpg";
import TeamHelpedImg3 from "../../../../../Images/How-we-work/helpedTeam3.jpg";

const HowWeHelped = () => {
  return (
    <div className="bg-sky-100">
      <div className="container mx-auto lg:px-20 px-5 pt-10">
        <h1 className="lg:text-4xl text-3xl font-medium text-center">
          See how we helped them
        </h1>
        <div className="mt-10 grid gap-0  grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center pb-14">
          <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-xl mt-10 lg:mt-0">
            <figure className="">
              <img src={TeamHelpedImg1} alt="Shoes" />
            </figure>
            <div className="p-4">
              <h1 className="text-lg font-bold text-sky-700">
                <a href="">DEVOPS SERVICES</a>
              </h1>
              <h1 className="md:text-3xl text-2xl lg:text-4xl font-medium text-black mt-2">
                <a href="">ArcusFi</a>
              </h1>
              <br />
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Aliquid, a? Provident ratione fugiat maiores quas consequatur
                laboriosam aspernatur, culpa quos error consequuntur soluta fuga
                libero aliquam in
              </p>
              <div className="text-center">
                <button className="btn rounded-full border-2 border-sky-700 bg-transparent text-sky-700 hover:bg-transparent hover:border-sky-700 px-8 mt-7 text-base ">
                  Read the case
                </button>
              </div>
            </div>
          </div>
          <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-xl mt-10 lg:mt-0">
            <figure>
              <img src={TeamHelpedImg2} alt="Shoes" />
            </figure>
            <div className="p-4">
              <h1 className="text-lg font-bold text-sky-700">
                <a href="">DEDICATED TEAM</a>
              </h1>
              <h1 className="md:text-3xl text-2xl lg:text-4xl font-medium text-black mt-2">
                <a href="">Rufus Labs</a>
              </h1>
              <br />
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Aliquid, a? Provident ratione fugiat maiores quas consequatur
                laboriosam aspernatur, culpa quos error consequuntur soluta fuga
                libero aliquam in
              </p>
              <div className="text-center">
                <button className="btn rounded-full border-2 border-sky-700 bg-transparent text-sky-700 hover:bg-transparent hover:border-sky-700 px-8 mt-7 text-base ">
                  Read the case
                </button>
              </div>
            </div>
          </div>
          <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-xl mt-10 lg:mt-0">
            <figure>
              <img src={TeamHelpedImg3} alt="Shoes" />
            </figure>
            <div className="p-4">
              <h1 className="text-lg font-bold text-sky-700">
                <a href="">SOFTWARE DEVELOPMENT</a>
              </h1>
              <h1 className="md:text-3xl text-2xl lg:text-4xl font-medium text-black mt-2">
                <a href="">BrandVerge</a>
              </h1>
              <br />
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Aliquid, a? Provident ratione fugiat maiores quas consequatur
                laboriosam aspernatur, culpa quos error consequuntur soluta fuga
                libero aliquam in
              </p>
              <div className="text-center">
                <button className="btn rounded-full border-2 border-sky-700 bg-transparent text-sky-700 hover:bg-transparent hover:border-sky-700 px-8 mt-7 text-base ">
                  Read the case
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWeHelped;
