import React from "react";
import Collaboration1 from "../../../../../Images/How-we-work/colaboration1.png";
import Collaboration2 from "../../../../../Images/How-we-work/colaboration2.png";
import Collaboration3 from "../../../../../Images/How-we-work/colaboration3.png";
import Collaboration4 from "../../../../../Images/How-we-work/colaboration4.png";
import Collaboration5 from "../../../../../Images/How-we-work/colaboration5.png";
import Collaboration6 from "../../../../../Images/How-we-work/colaboration6.png";

const CollaborationProcess = () => {
  return (
    <div className="bg-blue-900 text-white">
      <div className="container mx-auto lg:px-20 px-5 py-8">
        <div className="text-center mt-12 ">
          <h1 className="lg:text-4xl text-2xl  md:text-3xl font-medium mt-4">
            Our Collaboration Process
          </h1>
          <p className="text-lg leading-8 mt-4">
            From the beginning of any project until the end, <br />
            we make sure that our solutions follow best developing, release
            practices.beginning
          </p>
        </div>
        <div className="lg:flex items-center justify-center  py-8">
          <div className="lg:mr-8">
            <div className="flex items-center">
              <div>
                <img src={Collaboration1} alt="" />
              </div>
              <div>
                <p className="text-lg ml-8 leading-8">
                  <b>Meeting </b> Tell us more about the needs of your
                  <br />
                  business and let us understand how we can
                  <br />
                  help.
                </p>
              </div>
            </div>
            <br />
            <div className="flex items-center">
              <div>
                <img src={Collaboration2} alt="" />
              </div>
              <div>
                <p className="text-lg ml-8  leading-8">
                  <b>Proposal </b>After knowing your needs, our
                  <br />
                  technical team will get together to prepare a
                  <br />
                  proposal that fits your needs.
                </p>
              </div>
            </div>
            <br />
            <div className="flex items-center">
              <div>
                <img src={Collaboration3} alt="" />
              </div>
              <div>
                <p className="text-lg ml-8  leading-8">
                  <b>Proposal </b>After knowing your needs, our
                  <br />
                  technical team will get together to prepare a
                  <br />
                  proposal that fits your needs.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <div>
                <img src={Collaboration4} alt="" />
              </div>
              <div>
                <p className="text-lg ml-8  leading-8">
                  <b>Proposal </b>After knowing your needs, our
                  <br />
                  technical team will get together to prepare a
                  <br />
                  proposal that fits your needs.
                </p>
              </div>
            </div>
            <br />
            <div className="flex items-center">
              <div>
                <img src={Collaboration5} alt="" />
              </div>
              <div>
                <p className="text-lg ml-8  leading-8">
                  <b>Proposal </b>After knowing your needs, our
                  <br />
                  technical team will get together to prepare a
                  <br />
                  proposal that fits your needs.
                </p>
              </div>
            </div>
            <br />
            <div className="flex items-center">
              <div>
                <img src={Collaboration6} alt="" />
              </div>
              <div>
                <p className="text-lg ml-8  leading-8">
                  <b>Proposal </b>After knowing your needs, our
                  <br />
                  technical team will get together to prepare a
                  <br />
                  proposal that fits your needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollaborationProcess;
