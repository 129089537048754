import React from "react";

const AwsService = () => {
  return (
    <div className="container mx-auto lg:px-20 px-5 my-14">
      <div className="text-center">
        <h1 className="text-2xl text-blue-700 font-bold">AWS SERVICES</h1>
        <h1 className="lg:text-4xl text-3xl text-black font-bold">
          Our Services on the AWS Cloud
        </h1>
      </div>
      <div className="grid gap-0 lg:gap-8 grid-cols-1 lg:grid-cols-3 my-10">
        <div className="text-center shadow-xl rounded-xl duration-200 hover:shadow-2xl p-5 mt-3 ">
          <h1 className="text-3xl">
            <a href="" className="text-blue-700  hover:text-[#ff6700]">
              AWS Migration
            </a>
          </h1>
          <p className="text-base text-slate-500 mt-3 leading-8">
            Most companies <b>migrate to the cloud</b>
            <br />
            to reduce IT infrastructure costs since
            <br />
            the Cloud is the new normal for all-sized <br />
            businesses.
          </p>
        </div>
        <div className="text-center shadow-xl rounded-xl duration-200 hover:shadow-2xl p-5 mt-3">
          <h1 className="text-3xl">
            <a href="" className="text-blue-700  hover:text-[#ff6700]">
              AWS Well-Architected
            </a>
          </h1>
          <p className="text-base text-slate-500 mt-3 leading-8">
            Most companies <b>migrate to the cloud</b>
            <br />
            to reduce IT infrastructure costs since
            <br />
            the Cloud is the new normal for all-sized <br />
            businesses.
          </p>
        </div>
        <div className="text-center shadow-xl rounded-xl duration-200 hover:shadow-2xl p-5 mt-3">
          <h1 className="text-3xl">
            <a href="" className="text-blue-700  hover:text-[#ff6700]">
              AWS Consulting
            </a>
          </h1>
          <p className="text-base text-slate-500 mt-3 leading-8">
            Most companies <b>migrate to the cloud</b>
            <br />
            to reduce IT infrastructure costs since
            <br />
            the Cloud is the new normal for all-sized <br />
            businesses.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AwsService;
