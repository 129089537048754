import React from "react";
import "./Blogs.css";
import BlogsImg1 from "../../../Images/Blogs/blogsImg1.jpg";
import BlogsImg2 from "../../../Images/Blogs/blogsImg2.jpg";
import BlogsImg3 from "../../../Images/Blogs/blogsImg3.jpg";
import BlogsImg4 from "../../../Images/Blogs/blogsImg4.jpg";
import BlogsImg5 from "../../../Images/Blogs/blogsImg5.jpg";
import BlogsImg6 from "../../../Images/Blogs/blogsImg6.jpg";
import ServiceIconImg1 from "../../../Images/Blogs/serviceIcon1.png";
import ServiceIconImg2 from "../../../Images/Blogs/serviceIcon2.png";
import ServiceIconImg3 from "../../../Images/Blogs/serviceIcon3.png";
import { Link } from "react-router-dom";

const Blogs = () => {
  return (
    <div>
      <div className="lg:pt-24 pt-20 blogs-banner">
        <div className="container mx-auto lg:px-20 px-5 py-20">
          <h1 className="text-center lg:text-5xl text-3xl font-bold text-white">
            {/* Our Digital Transformation Blog */}
            Welcome To Our Job Post
          </h1>
        </div>
      </div>

      <div className="bg-slate-100">
        <div className="container mx-auto lg:px-16 px-5 py-10">
          <div className="lg:flex justify-between">
            <div>
              <div className="flex justify-center">
                <div class="form-control">
                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="Search…"
                      class="input input-bordered w-[350px]"
                    />
                    <button class="btn btn-square bg-blue-800 hover:bg-blue-800">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="grid gap-0 lg:gap-5 grid-cols-1 lg:grid-cols-2 mt-10">
                <div className="card lg:w-96 md:w-80 w-full bg-base-100 shadow-xl hover:shadow-2xl mt-10 lg:mt-0">
                  <figure className="group relative">
                    <img src={BlogsImg1} className="w-full" />
                    <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-blue-900 opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
                      <a class="mt-5 px-8 py-3  text-white" href="#">
                        <i class="fa-solid fa-arrow-right text-2xl"></i>
                      </a>
                    </div>
                  </figure>
                  <div className="p-4">
                    <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
                      <a className=" hover:text-blue-700 duration-200" href="">
                        6 Reasons your DevOps team in
                      </a>
                    </h1>

                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Aliquid, a? Provident ratione fugiat maiores quas...
                    </p>
                    <div className="mt-4">
                      <a className="text-sm text-blue-900" href="">
                        Read More
                      </a>
                    </div>
                  </div>
                  <hr class="mb-3 w-full h-[1px] bg-gray-200 rounded border-0 dark:bg-gray-700"></hr>
                  <p className="px-4 pb-4 text-small text-slate-500">
                    November 8, 2022
                  </p>
                </div>
                <div className="card lg:w-96 md:w-80 w-full bg-base-100 shadow-xl hover:shadow-2xl mt-10 lg:mt-0">
                  <figure className="group relative">
                    <img src={BlogsImg2} className="w-full" />
                    <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-blue-900 opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
                      <a class="mt-5 px-8 py-3  text-white" href="#">
                        <i class="fa-solid fa-arrow-right text-2xl"></i>
                      </a>
                    </div>
                  </figure>
                  <div className="p-4">
                    <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
                      <a className=" hover:text-blue-700 duration-200" href="">
                        6 Reasons your DevOps team in
                      </a>
                    </h1>

                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Aliquid, a? Provident ratione fugiat maiores quas...
                    </p>
                    <div className="mt-4">
                      <a className="text-sm text-blue-900" href="">
                        Read More
                      </a>
                    </div>
                  </div>
                  <hr class="mb-3 w-full h-[1px] bg-gray-200 rounded border-0 dark:bg-gray-700"></hr>
                  <p className="px-4 pb-4 text-small text-slate-500">
                    November 8, 2022
                  </p>
                </div>
                <div className="card lg:w-96 md:w-80 w-full bg-base-100 shadow-xl hover:shadow-2xl mt-10 lg:mt-0">
                  <figure className="group relative">
                    <img src={BlogsImg3} className="w-full" />
                    <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-blue-900 opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
                      <a class="mt-5 px-8 py-3  text-white" href="#">
                        <i class="fa-solid fa-arrow-right text-2xl"></i>
                      </a>
                    </div>
                  </figure>
                  <div className="p-4">
                    <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
                      <a className=" hover:text-blue-700 duration-200" href="">
                        6 Reasons your DevOps team in
                      </a>
                    </h1>

                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Aliquid, a? Provident ratione fugiat maiores quas...
                    </p>
                    <div className="mt-4">
                      <a className="text-sm text-blue-900" href="">
                        Read More
                      </a>
                    </div>
                  </div>
                  <hr class="mb-3 w-full h-[1px] bg-gray-200 rounded border-0 dark:bg-gray-700"></hr>
                  <p className="px-4 pb-4 text-small text-slate-500">
                    November 8, 2022
                  </p>
                </div>
                <div className="card lg:w-96 md:w-80 w-full bg-base-100 shadow-xl hover:shadow-2xl mt-10 lg:mt-0">
                  <figure className="group relative">
                    <img src={BlogsImg4} className="w-full" />
                    <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-blue-900 opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
                      <a class="mt-5 px-8 py-3  text-white" href="#">
                        <i class="fa-solid fa-arrow-right text-2xl"></i>
                      </a>
                    </div>
                  </figure>
                  <div className="p-4">
                    <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
                      <a className=" hover:text-blue-700 duration-200" href="">
                        6 Reasons your DevOps team in
                      </a>
                    </h1>

                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Aliquid, a? Provident ratione fugiat maiores quas...
                    </p>
                    <div className="mt-4">
                      <a className="text-sm text-blue-900" href="">
                        Read More
                      </a>
                    </div>
                  </div>
                  <hr class="mb-3 w-full h-[1px] bg-gray-200 rounded border-0 dark:bg-gray-700"></hr>
                  <p className="px-4 pb-4 text-small text-slate-500">
                    November 8, 2022
                  </p>
                </div>
                <div className="card lg:w-96 md:w-80 w-full bg-base-100 shadow-xl hover:shadow-2xl mt-10 lg:mt-0">
                  <figure className="group relative">
                    <img src={BlogsImg5} className="w-full" />
                    <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-blue-900 opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
                      <a class="mt-5 px-8 py-3  text-white" href="#">
                        <i class="fa-solid fa-arrow-right text-2xl"></i>
                      </a>
                    </div>
                  </figure>
                  <div className="p-4">
                    <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
                      <a className=" hover:text-blue-700 duration-200" href="">
                        6 Reasons your DevOps team in
                      </a>
                    </h1>

                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Aliquid, a? Provident ratione fugiat maiores quas...
                    </p>
                    <div className="mt-4">
                      <a className="text-sm text-blue-900" href="">
                        Read More
                      </a>
                    </div>
                  </div>
                  <hr class="mb-3 w-full h-[1px] bg-gray-200 rounded border-0 dark:bg-gray-700"></hr>
                  <p className="px-4 pb-4 text-small text-slate-500">
                    November 8, 2022
                  </p>
                </div>
                <div className="card lg:w-96 md:w-80 w-full bg-base-100 shadow-xl hover:shadow-2xl mt-10 lg:mt-0">
                  <figure className="group relative">
                    <img src={BlogsImg6} className="w-full" />
                    <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-blue-900 opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
                      <a class="mt-5 px-8 py-3  text-white" href="#">
                        <i class="fa-solid fa-arrow-right text-2xl"></i>
                      </a>
                    </div>
                  </figure>
                  <div className="p-4">
                    <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
                      <a className=" hover:text-blue-700 duration-200" href="">
                        6 Reasons your DevOps team in
                      </a>
                    </h1>

                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Aliquid, a? Provident ratione fugiat maiores quas...
                    </p>
                    <div className="mt-4">
                      <a className="text-sm text-blue-900" href="">
                        Read More
                      </a>
                    </div>
                  </div>
                  <hr class="mb-3 w-full h-[1px] bg-gray-200 rounded border-0 dark:bg-gray-700"></hr>
                  <p className="px-4 pb-4 text-small text-slate-500">
                    November 8, 2022
                  </p>
                </div>
              </div>
            </div>
            {/* RECENT POST */}
            <div className="lg:mt-24 mt-10 lg:w-80 w-full">
              <div className="">
                <h1 className="font-bold text-3xl">Recent Job Post</h1>
                <div className="mt-4">
                  <div className="shadow-xl rounded-xl p-3 mt-1">
                    <div className="flex items-center">
                      <img
                        src={BlogsImg6}
                        className="lg:w-[137px] mr-[17px]"
                        alt=""
                      />

                      <p className="text-[14px] leading-[16px]">
                        <a href="" className="hover:text-blue-800 duration-100">
                          {" "}
                          Sometimes you may want to create temporary login
                          details in WordPress
                        </a>
                      </p>
                    </div>
                    <p className="text-sm text-slate-500 mt-2">
                      October 06 2022
                    </p>
                  </div>
                  <div className="shadow-xl rounded-xl p-3 mt-1">
                    <div className="flex items-center">
                      <img
                        src={BlogsImg4}
                        className="lg:w-[137px] mr-[17px]"
                        alt=""
                      />

                      <p className="text-[14px] leading-[16px]">
                        <a href="" className="hover:text-blue-800 duration-100">
                          {" "}
                          Sometimes you may want to create temporary login
                          details in WordPress
                        </a>
                      </p>
                      {/* </div> */}
                    </div>
                    <p className="text-sm text-slate-500 mt-2">
                      October 06 2022
                    </p>
                  </div>
                  <div className="shadow-xl rounded-xl p-3 mt-1">
                    <div className="flex items-center">
                      <img
                        src={BlogsImg1}
                        className="lg:w-[137px] mr-[17px]"
                        alt=""
                      />

                      <p className="text-[14px] leading-[16px]">
                        <a href="" className="hover:text-blue-800 duration-100">
                          {" "}
                          Sometimes you may want to create temporary login
                          details in WordPress
                        </a>
                      </p>
                      {/* </div> */}
                    </div>
                    <p className="text-sm text-slate-500 mt-2">
                      October 06 2022
                    </p>
                  </div>
                  <div className="shadow-xl rounded-xl p-3 mt-1">
                    <div className="flex items-center">
                      <img
                        src={BlogsImg3}
                        className="lg:w-[137px] mr-[17px]"
                        alt=""
                      />

                      <p className="text-[14px] leading-[16px]">
                        <a href="" className="hover:text-blue-800 duration-100">
                          {" "}
                          Sometimes you may want to create temporary login
                          details in WordPress
                        </a>
                      </p>
                      {/* </div> */}
                    </div>
                    <p className="text-sm text-slate-500 mt-2">
                      October 06 2022
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <h1 className="font-bold text-3xl">Our Services</h1>
                <div className="flex items-center mt-6">
                  <img
                    src={ServiceIconImg1}
                    className="w-20 mr-[10px]"
                    alt=""
                  />

                  <p className="text-2xl leading-[23px]">
                    <Link
                      to="/software-development"
                      className="hover:text-[#ff6700] duration-100"
                    >
                      {" "}
                      Software Development
                    </Link>
                  </p>
                </div>
                <div className="flex items-center">
                  <img
                    src={ServiceIconImg2}
                    className="w-20 mr-[10px]"
                    alt=""
                  />

                  <p className="text-2xl leading-[23px]">
                    <Link
                      to="/devops-outsourcing"
                      className="hover:text-[#ff6700] duration-100"
                    >
                      {" "}
                      DevOps Services
                    </Link>
                  </p>
                </div>
                <div className="flex items-center">
                  <img
                    src={ServiceIconImg3}
                    className="w-20 mr-[10px]"
                    alt=""
                  />

                  <p className="text-2xl leading-[23px]">
                    <Link
                      to="/how-we-work"
                      className="hover:text-[#ff6700] duration-100"
                    >
                      {" "}
                      Staff Augmentation
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
