import React from "react";

const ContactUs = () => {
  return (
    <div className="bg-sky-100 py-10">
      <div className="container mx-auto lg:px-20 px-5">
        <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center justify-items-center">
          <div className="shadow-2xl p-8 w-full  lg:flex justify-center rounded-2xl items-center bg-white">
            <form action="" className="mt-10px">
              <div className="lg:flex md:flex items-center">
                <div className="lg:mr-7 md:mr-7 mb-5 lg:mb-0">
                  <label className="text-sm text-slate-400 pb-2" htmlFor="">
                    First name<sup className="text-red-500">*</sup>
                  </label>
                  <br />
                  <input
                    className="input w-full border-slate-600  rounded-2xl h-9 bg-[#f5f8fa]"
                    type="text"
                  />
                </div>
                <div>
                  <label className="text-sm text-slate-400 pb-2" htmlFor="">
                    Company name<sup className="text-red-500">*</sup>
                  </label>
                  <br />
                  <input
                    className="input w-full border-slate-600  rounded-2xl h-9 bg-[#f5f8fa]"
                    type="text"
                  />
                </div>
              </div>
              <br />

              <div className="lg:flex md:flex items-center">
                <div className="lg:mr-7 md:mr-7 mb-5 lg:mb-0">
                  <label className="text-sm text-slate-400 pb-2" htmlFor="">
                    Email<sup className="text-red-500">*</sup>
                  </label>
                  <br />
                  <input
                    className="input w-full border-slate-600  rounded-2xl h-9 bg-[#f5f8fa]"
                    type="text"
                  />
                </div>
                <div>
                  <label className="text-sm text-slate-400 pb-2" htmlFor="">
                    Phone number
                  </label>
                  <br />
                  <input
                    className="input w-full border-slate-600  rounded-2xl h-9 bg-[#f5f8fa]"
                    type="text"
                  />
                </div>
              </div>
              <br />
              <div>
                <label className="text-sm text-slate-400 pb-2" htmlFor="">
                  Describe your project<sup className="text-red-500">*</sup>
                </label>
                <br />
                <textarea
                  name=""
                  className="textarea h-16 lg:w-[460px] md:w-[460px]  w-full border-slate-600  bg-[#f5f8fa]"
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>

              <div className="mt-5">
                <button className="btn rounded-full  bg-[#ff6700] hover:bg-[#ff6700] border-[#ff6700] hover:border-[#ff6700]">
                  submit
                </button>
              </div>
            </form>
          </div>

          <div>
            {" "}
            <div className="text-center mt-12 mb-14">
              <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
                CONTACT US
              </h1>
              <h1 className="lg:text-4xl text-2xl text-black md:text-3xl font-semibold mt-2">
                We want to meet you
              </h1>
              <p className="text-lg mt-3 text-slate-600">
                And you’ll get a full spectrum of Software Development <br />
                benefits{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
