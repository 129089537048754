import React from "react";
import TImeZoneImg from "../../../../../Images/sassApplicationDevelopment/sameTimeZone.png";

const SameTimeZone = () => {
  return (
    <div className="container mx-auto px-5 lg:px-20 py-10">
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center justify-items-center">
        <div>
          <h1 className="text-blue-700 text-2xl font-bold">NEARSHORE</h1>
          <h1 className="text-3xl lg:text-4xl text-black font-bold mt-2">
            Same Time Zone
          </h1>
          <p className="leading-9 text-lg text-slate-500">
            A service to transform the way Fintechs conduct their <br />
            businesses with product design and cloud-native development.
          </p>
        </div>
        <div>
          <img src={TImeZoneImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SameTimeZone;
