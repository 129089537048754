import React from "react";
import AboutBanner from "./Section/AboutBanner/AboutBanner";
import AboutUsDescription from "./Section/AboutUsDescription/AboutUsDescription";

const AboutUs = () => {
  return (
    <div>
      <AboutBanner />
      <AboutUsDescription />
    </div>
  );
};

export default AboutUs;
