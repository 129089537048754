import React from "react";
import brandVergeImg from "../../../../../Images/softwareDevelopment/brandVerge.png";
import RufusLabs from "../../../../../Images/softwareDevelopment/rufuslabsImg.png";
import arufiImg from "../../../../../Images/softwareDevelopment/arcusfiImg.png";

const BrandVerge = () => {
  return (
    <div>
      <div className="bg-[#1299A2]">
        <div className="container mx-auto px-4 lg:px-20 text-white">
          <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center justify-items-center">
            <div className="mt-6 mb-8 lg:mb-0">
              <img src={brandVergeImg} alt="" />
            </div>
            <div className="pb-8 lg:pb-0">
              <h1 className="text-xl ">CASE STUDY</h1>
              <h1 className="lg:text-5xl text-3xl font-bold mt-1 mb-6">
                <a href="">BrandVerge</a>
              </h1>
              <p className="text-lg">
                BrandVerge needed a refactor of their AngularJS based <br />
                application and sought to migrate to ReactJS. The goal was to
                <br />
                improve the overall performance of the application and extend
                <br />
                its capabilities.
              </p>
              <button className="btn rounded-full bg-transparent border-2 px-10 mt-10 hover:border-[#fff] hover:bg-transparent border-[#fff]">
                See full Case Study
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 lg:px-20 mt-8 lg:mt-0">
        <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center justify-items-center">
          <div>
            <h1 className="text-xl text-blue-700 ">CASE STUDY</h1>
            <h1 className="lg:text-5xl text-blue-700 text-3xl font-bold mt-1 mb-6">
              <a href="">RufusLabs</a>
            </h1>
            <p className="text-lg text-black">
              RufusLabs wanted to extend their web development team by
              <br />
              adding Javascript developers for the release of new features in
              <br />
              their NodeJS + ReactJS + Android-based application.
            </p>
            <button className="btn rounded-full bg-transparent border-2 px-10 mt-10 hover:border-blue-700 hover:bg-blue-700 bg-blue-700 border-blue-700">
              See full Case Study
            </button>
          </div>
          <div className="mt-6 mb-8 lg:mb-0">
            <img src={RufusLabs} alt="" />
          </div>
        </div>
      </div>
      <div className="bg-[#2C68BF]">
        <div className="container mx-auto px-4 lg:px-20 text-white">
          <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center justify-items-center">
            <div className="mt-6">
              <img src={arufiImg} alt="" />
            </div>
            <div className="pb-8 lg:pb-0">
              <h1 className="text-xl ">CASE STUDY</h1>
              <h1 className="lg:text-5xl text-3xl font-bold mt-1 mb-6">
                <a href="">ArcusFi</a>
              </h1>
              <p className="text-lg">
                ArcusFi chose DevelopersThrill to improve their security within
                their AWS <br />
                environment, servers, and software. They also required Staff
                <br />
                Augmentation for their team and some DevOps services to be
                <br />
                PCI compliant.
              </p>
              <button className="btn rounded-full bg-transparent border-2 px-10 mt-10 hover:border-[#fff] hover:bg-transparent border-[#fff]">
                See full Case Study
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandVerge;
