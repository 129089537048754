import React from "react";
import Accelete from "../Home/Section/Accelarate/Accelete";
import BlogsPost from "../HowToWork/Section/BlogsPost/BlogsPost";
import DevOpsBenefits from "./Section/DevOpsBenefits/DevOpsBenefits";
import HireOutsourceDevOps from "./Section/HireOutsourceDevOps/HireOutsourceDevOps";
import IndustryLeadingService from "./Section/IndustryLeadingService/IndustryLeadingService";
import OutsourceService from "./Section/OutsourceService/OutsourceService";
import OutsourcingBanner from "./Section/OutsourcingBanner/OutsourcingBanner";
import TheseDevOpsRoles from "./Section/TheseDevOpsRoles/TheseDevOpsRoles";

const DevOpsOutsourcing = () => {
  return (
    <div>
      <OutsourcingBanner />
      <OutsourceService />
      <HireOutsourceDevOps />
      <TheseDevOpsRoles />
      <IndustryLeadingService />
      <DevOpsBenefits />
      <BlogsPost />
      <Accelete />
    </div>
  );
};

export default DevOpsOutsourcing;
