import React from "react";
import "./Learn.css";
const Learn = () => {
  return (
    <div className="container mx-auto lg:px-20 px-8">
      <div>
        <div className="learn-container text-center p-8 text-white">
          <h1 className="text-3xl font-bold mt-4">
            {" "}
            Learn How to Put Our DevOps, Software Development, <br /> and Staff
            Augmentation Services to Work for You
          </h1>
          <br />
          <p className="text-lg font-normal	">
            Book a 20-minute call to understand how DevelopersThrill can be deployed in
            your organization to accelerate <br /> DevOps adoption and growth.
          </p>
          <button
            style={{ fontFamily: "Montserrat, Sans-serif" }}
            className="text-color  rounded-full px-8 py-2  btn-bg mt-8"
          >
            Schedule an Exploratory Call
          </button>
        </div>
      </div>
    </div>
  );
};

export default Learn;
