import React from "react";
import "./SoftwareService.css";
import serviceImg1 from "../../../../../Images/softwareDevelopment/softwareServiceImg1.png";
import serviceImg2 from "../../../../../Images/softwareDevelopment/softwareServiceImg2.png";
import serviceImg3 from "../../../../../Images/softwareDevelopment/softwareServiceImg3.png";
import serviceImg4 from "../../../../../Images/softwareDevelopment/softwareServiceImg4.png";
const SoftwareService = () => {
  return (
    <div className="software-container py-14">
      <div className="container mx-auto px-5 lg:px-20">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-blue-700">
            OUR SOFTWARE DEVELOPMENT SERVICES​
          </h1>
          <h1 className="lg:text-5xl text-4xl text-white mt-2 font-bold">
            DevelopersThrill Offers What You Need
          </h1>
        </div>
        <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center justify-items-center text-white my-8">
          <div>
            <div className="flex items-center">
              <img className="w-28 mr-4" src={serviceImg1} alt="" />
              <div>
                <h1 className="text-lg font-bold">
                  Custom Software Development
                </h1>
                <p className="text-base text-slate-400 leading-6 mt-2">
                  From design to deployment, we deliver unique software <br />
                  solutions that solve your app development and <br /> software
                  challenges.
                </p>
              </div>
            </div>
            <div className="flex items-center lg:mt-10 mt-5">
              <img className="w-28 mr-4" src={serviceImg2} alt="" />
              <div>
                <h1 className="text-lg font-bold">Serverless Development</h1>
                <p className="text-base text-slate-400 leading-6 mt-2">
                  We’ll help you build and deploy a highly scalable
                  <br />
                  serverless app to optimize your processes and get to
                  <br /> market faster.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <img className="w-28 mr-4" src={serviceImg3} alt="" />
              <div>
                <h1 className="text-lg font-bold">SaaS App Development</h1>
                <p className="text-base text-slate-400 leading-6 mt-2">
                  We use our SaaS expertise to build robust, secure, high-{" "}
                  <br />
                  quality apps while following leading-edge development
                  <br />
                  practices.
                </p>
              </div>
            </div>
            <div className="flex items-center lg:mt-10 mt-5">
              <img className="w-28 mr-4" src={serviceImg4} alt="" />
              <div>
                <h1 className="text-lg font-bold">Web Design and UI/UX</h1>
                <p className="text-base text-slate-400 leading-6 mt-2">
                  Create extraordinary experiences for your customers
                  <br />
                  with a user-friendly and responsive website that helps
                  <br /> you achieve your business goals.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button className="btn rounded-full bg-transparent border-2 px-12 mt-10 hover:border-[#fff] hover:bg-transparent border-[#fff]">
            Get A Quote
          </button>
        </div>
      </div>
    </div>
  );
};

export default SoftwareService;
