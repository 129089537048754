import React, { useEffect, useState } from "react";
import "./Navbar.css";

import { Link, NavLink } from "react-router-dom";
import Logo from "../../../../Images/Logo/logo.png";
import NavbarImg1 from "../../../../Images/Navbar/serviceImg1.svg";
import NavbarImg2 from "../../../../Images/Navbar/serviceImg2.svg";
import NavbarImg3 from "../../../../Images/Navbar/serviceImg3.svg";
import NavbarImg4 from "../../../../Images/Navbar/serviceImg4.svg";
import NavbarImg5 from "../../../../Images/Navbar/serviceImg5.svg";
import NavbarImg6 from "../../../../Images/Navbar/serviceImg6.svg";
const Navbar = () => {
  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(10);

  // on scroll hide and show navbar functionality
  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(true);
      } else {
        // if scroll up show the navbar
        setShow(false);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  // RESPONSIVE TOGGLER BTN STATE
  const [open, setOpen] = useState(false);
  // ONCLICK NAVIGATE

  return (
    <nav className={`active ${show && "hidden"}`}>
      <div className="fixed  top-0 w-[100%] z-50">
        <div className="nav-active px-4 py-1 lg:p-0">
          <div className="p-4 container mx-auto lg:px-8 md:px-4">
            <nav className="flex items-center justify-between d-flex">
              {/* PROJECT LOGO */}
              <div>
                <Link to="/">
                  <img src={Logo} className="w-[15rem]" alt="" />
                  {/* {logo} */}
                </Link>
              </div>
              <div className="">
                {/* NAV ITEM */}
                <ul
                  className={`lg:flex w-100 h-96 lg:h-auto lg:w-full block lg:items-center navbar absolute duration-500 ease-in lg:static top-16 lg:bg-transparent bg-white overflow-auto  ${
                    open ? "left-[-10px] top-20" : "left-[-1080px] "
                  }`}
                >
                  <li className="block text-center">
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li
                    tabindex="5"
                    className="block text-center dropdown  static px-3"
                  >
                    <h1 className=" dropdown-toggle lg:text-white cursor-pointer">
                      Services <i class="fa-solid fa-caret-down pl-1"></i>
                    </h1>
                    <ul
                      tabindex="5"
                      className="dropdown-content menu  p-2 shadow bg-base-200 rounded-box w-80 mt-3 pb-2"
                    >
                      <li>
                        <Link
                          to="/devops-outsourcing"
                          style={{ color: "black" }}
                        >
                          <img className="w-14 h-10" src={NavbarImg1} alt="" />
                          DevOps Outsourcing
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/devops-financial-services"
                          style={{ color: "black" }}
                        >
                          <img className="w-14 h-10" src={NavbarImg2} alt="" />
                          DevOps Financial Services
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/devops-consulting-services"
                          style={{ color: "black" }}
                        >
                          <img className="w-14 h-10" src={NavbarImg3} alt="" />
                          DevOps Consulting
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/software-development"
                          style={{ color: "black" }}
                        >
                          <img className="w-14 h-10" src={NavbarImg4} alt="" />
                          Software Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/saas-application-development"
                          style={{ color: "black" }}
                        >
                          <img className="w-14 h-10" src={NavbarImg5} alt="" />
                          SaaS Application Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/aws-managed-services"
                          style={{ color: "black" }}
                        >
                          <img className="w-14 h-10" src={NavbarImg6} alt="" />
                          AWS Managed Services
                        </Link>
                      </li>
                    </ul>
                    {/* <div
                      tabindex="5"
                      className="dropdown-content w-96 menu shadow  rounded-box container bg-white p-5"
                    >
                      <div
                        className="grid gap-0 grid-cols-1 lg:grid-cols-3 md:grid-cols-2"
                        tabindex="0"
                      >
                        <div>
                          <div>
                            <div className="flex items-center">
                              <img src={NavbarImg1} className="w-12" alt="" />
                              <p className="text-black">
                                <a href="" style={{ color: "black" }}>
                                  DevOps Outsourcing
                                </a>
                              </p>
                            </div>
                            <p>Enable DevOps for faster time-to-market.</p>
                          </div>
                        </div>
                        <div></div>
                        <div></div>
                      </div>
                    </div> */}
                  </li>

                  <li className="block text-center">
                    <NavLink to="/how-we-work">Industries</NavLink>
                  </li>
                  <li className="block text-center">
                    <NavLink to="/">Resources</NavLink>
                  </li>
                  <li className="block text-center">
                    <NavLink to="/about-us">About us</NavLink>
                  </li>
                  <li className="block text-center">
                    <NavLink to="/">Careers</NavLink>
                  </li>
                  <li className="block text-center">
                    <NavLink to="/job-post">Job Post</NavLink>
                  </li>

                  {/* RESPONSIVE LOGIN OR SIGN UP  BUTTON */}
                  <div className="lg:hidden flex items-center justify-around">
                    <Link
                      to="/contact"
                      className="btn nav-button  text-white rounded-full "
                    >
                      HIRE US
                    </Link>
                  </div>
                </ul>
              </div>
              <div className="hidden lg:block">
                <Link
                  to="/contact"
                  className="btn nav-button  text-white rounded-full "
                >
                  HIRE US
                </Link>
              </div>
              {/* NAVBAR TOGGLER ICON */}
              <div
                onClick={() => setOpen(!open)}
                className="w-10 h-10 lg:hidden"
              >
                {open ? (
                  <i class="fa-solid fa-xmark menu-icon"></i>
                ) : (
                  <i class="fa-solid fa-bars menu-icon"></i>
                )}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
