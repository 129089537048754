import React from "react";
import WhyIsItImg from "../../../../../Images/softwareDevelopment/whyisit.png";

const WhyIsIt = () => {
  return (
    <div className="container mx-auto px-5 lg:px-20">
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center justify-items-center py-16">
        <div>
          <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
            TOP RATED YEAR AFTER YEAR
          </h1>
          <h1 className="lg:text-4xl text-2xl text-black font-medium mt-2">
            Why DevelopersThrill?
          </h1>
          <div className="mt-5">
            <div className="flex items-center">
              <div>
                <i class="fa-solid fa-check mr-3 text-lg text-sky-600"></i>
              </div>
              <p className="text-lg text-slate-700 leading-6">
                We hire ‘A’ players—fully vetted and evaluated software <br />
                engineers.
              </p>
            </div>
            <div className="flex items-center mt-3">
              <div>
                <i class="fa-solid fa-check mr-3 text-lg text-sky-600"></i>
              </div>
              <p className="text-lg text-slate-700 leading-6">
                We provide end-to-end agile software development, from
                <br />
                design and architecture to implementation.
              </p>
            </div>
            <div className="flex items-center mt-3">
              <div>
                <i class="fa-solid fa-check mr-3 text-lg text-sky-600"></i>
              </div>
              <p className="text-lg text-slate-700 leading-6">
                We’re based in Mexico, not overseas, which means we’re in
                <br />
                your time zone! This allows us to provide immediate support,{" "}
                <br />
                shorter feedback loops, live collaboration, and higher <br />
                engineers.
              </p>
            </div>
          </div>
        </div>
        <div>
          <img src={WhyIsItImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default WhyIsIt;
