import { Route, Routes } from "react-router";
import "./App.css";
import AboutUs from "./Components/Pages/AboutUs/AboutUs";
import AwsManageService from "./Components/Pages/AwsManageService/AwsManageService";
import Blogs from "./Components/Pages/Blogs/Blogs";
import ContactPage from "./Components/Pages/ContactPage/ContactPage";
import DavOpsFinancialService from "./Components/Pages/DavOpsFinancialService/DavOpsFinancialService";
import DevOpsConsulting from "./Components/Pages/DevOpsConsulting/DevOpsConsulting";
import DevOpsOutsourcing from "./Components/Pages/DevOpsOutsourcing/DevOpsOutsourcing";

import Home from "./Components/Pages/Home/Home";
import HowToWork from "./Components/Pages/HowToWork/HowToWork";
import NotFound from "./Components/Pages/NotFound/NotFound";
import CookiePolicy from "./Components/Pages/PrivacyPage/CookiePolicy/CookiePolicy";
import PrivacyPolicy from "./Components/Pages/PrivacyPage/PrivacyPolicy/PrivacyPolicy";
import TermOfService from "./Components/Pages/PrivacyPage/TermOfService/TermOfService";
import SaasApplicationDevelopment from "./Components/Pages/SaasApplicationDevelopment/SaasApplicationDevelopment";
import Footer from "./Components/Pages/Shared/Footer/Footer";
import Navbar from "./Components/Pages/Shared/Navbar/Navbar";
import SoftwareDevelopment from "./Components/Pages/SoftwareDevelopment/SoftwareDevelopment";

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/how-we-work" element={<HowToWork />} />
        <Route path="/job-post" element={<Blogs />} />
        <Route path="/software-development" element={<SoftwareDevelopment />} />
        <Route
          path="/devops-consulting-services"
          element={<DevOpsConsulting />}
        />
        <Route path="/devops-outsourcing" element={<DevOpsOutsourcing />} />
        <Route
          path="/devops-financial-services"
          element={<DavOpsFinancialService />}
        />
        <Route
          path="/saas-application-development"
          element={<SaasApplicationDevelopment />}
        />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/aws-managed-services" element={<AwsManageService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/term-of-service" element={<TermOfService />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
