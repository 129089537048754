import React from "react";
import BusinessImg1 from "../../../../../Images/How-we-work/businessModal1.png";
import BusinessImg2 from "../../../../../Images/How-we-work/businessModal2.png";
import BusinessImg3 from "../../../../../Images/How-we-work/businessModal3.png";
import startProjectImg from "../../../../../Images/How-we-work/startProjectImg.PNG";

const BusinessModel = () => {
  return (
    <div className="container mx-auto lg:px-20 px-5 my-6">
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-3">
        <div class="card lg:w-80  w-full bg-base-100 shadow-2xl py-4 mt-6">
          <div className="flex justify-center">
            <img src={BusinessImg1} />
          </div>

          <div class=" text-center my-4 px-8">
            <h2 class="font-medium text-blue-900 text-2xl">
              <a className="hover:text-[#ff6700]" href="">
                Staff Augmentation
              </a>
            </h2>
            <p className=" text-base text-slate-700 mt-2">
              Are you in the need of finding
              <br />
              someone who can help you in your
              <br />
              DevOps tasks? We got you covered.
            </p>
          </div>
        </div>
        <div class="card lg:w-80  w-full bg-base-100 shadow-2xl py-4 mt-6">
          <div className="flex justify-center">
            <img src={BusinessImg2} />
          </div>

          <div class=" text-center my-4 px-8">
            <h2 class="font-medium text-blue-900 text-2xl">
              <a className="hover:text-[#ff6700]" href="">
                Dedicated Team
              </a>
            </h2>
            <p className=" text-base text-slate-700 mt-2">
              Are you in the need of finding
              <br />
              someone who can help you in your
              <br />
              DevOps tasks? We got you covered.
            </p>
          </div>
        </div>
        <div class="card lg:w-80  w-full bg-base-100 shadow-2xl py-4 mt-6">
          <div className="flex justify-center">
            <img src={BusinessImg3} />
          </div>

          <div class=" text-center my-4 px-8">
            <h2 class="font-medium text-blue-900 text-2xl">
              <a className="hover:text-[#ff6700]" href="">
                Project Based Model
              </a>
            </h2>
            <p className=" text-base text-slate-700 mt-2">
              Are you in the need of finding
              <br />
              someone who can help you in your
              <br />
              DevOps tasks? We got you covered.
            </p>
          </div>
        </div>
      </div>
      <div className="text-center lg:flex items-center justify-center mt-8">
        <div>
          <h1 className="lg:text-4xl text-3xl font-medium mr-4 lg:translate-y-[10px]">
            Start your project with us!
          </h1>
        </div>
        <div>
          <button className="btn rounded-full border-2 border-sky-700 bg-transparent text-sky-700 hover:bg-transparent hover:border-sky-700 px-12 mt-7 text-base ">
            Contact us!
          </button>
        </div>
      </div>
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center justify-center mt-5">
        <div>
          <img src={startProjectImg} alt="" />
        </div>
        <div>
          <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
            OUR CASE STUDIES
          </h1>
          <h1 className="lg:text-4xl text-2xl text-black font-medium mt-4">
            How do we select our
            <br />
            ‘A’ players talent?
          </h1>
          <p className="text-lg mt-6 text-slate-600">
            We count with a unique ‘A’ players talent process in order to
            <br />
            select pre-vetted and highly qualified developers, which
            <br />
            includes the examination of soft skills, written tests and
            <br />
            technical interview.
          </p>
        </div>
      </div>
      <div className="grid gap-0 grid-cols-2 lg:grid-cols-4 items-center justify-items-center justify-center my-5">
        <div className="text-center shadow-2xl py-4 w-52 rounded-lg mt-3">
          <h1 className="text-6xl text-blue-900 font-bold">100%</h1>
          <h2 className="text-2xl text-blue-500 leading-5 mt-2">
            Cloud-Native
            <br />
            Solutions
          </h2>
        </div>
        <div className="text-center shadow-2xl py-4 w-52 rounded-lg mt-3">
          <h1 className="text-6xl text-blue-900 font-bold">90%</h1>
          <h2 className="text-2xl text-blue-500 leading-5 mt-2">
            Full Stack
            <br />
            Engineers
          </h2>
        </div>
        <div className="text-center shadow-2xl py-4 w-52 rounded-lg mt-3">
          <h1 className="text-6xl text-blue-900 font-bold">50+</h1>
          <h2 className="text-2xl text-blue-500 leading-5 mt-2">
            Serverless
            <br />
            Project
          </h2>
        </div>
        <div className="text-center shadow-2xl py-4 w-52 rounded-lg mt-3">
          <h1 className="text-6xl text-blue-900 font-bold">2%</h1>
          <h2 className="text-2xl text-blue-500 leading-5 mt-2">
            Technical
            <br />
            Debt
          </h2>
        </div>
      </div>
    </div>
  );
};

export default BusinessModel;
