import React from "react";
import ProspleImg from "../../../../../Images/sassApplicationDevelopment/prospleImg.jpg";

const ProspleEdutech = () => {
  return (
    <div className="container mx-auto px-5 lg:px-20 py-10">
      <div className="grid gap-0 lg:grid-cols-2 grid-cols-1 items-center justify-items-center my-10">
        <div>
          <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
            SCALE AND CAPTURE MARKET SHARE
          </h1>
          <h1 className="lg:text-5xl leadign-8 text-3xl text-black md:text-3xl font-medium mt-2">
            Multi-tenant and SaaS
            <br /> Application Architecture
          </h1>
          <p className="text-slate-500 leading-9 mt-6">
            Propel required to design the architecture of a Multi-tenant and
            Software-as- <br />
            a-service application with Amazon ECS, Amazon Lambda, and the
            serverless <br />
            framework.
          </p>
          <button className="btn rounded-full bg-transparent border-2 px-12 mt-8 hover:border-blue-700 hover:bg-transparent border-blue-700 text-blue-700">
            Read the Case Study INDUSTRY
          </button>
        </div>
        <div>
          <img src={ProspleImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ProspleEdutech;
