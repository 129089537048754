import React from "react";
import Img from "../../../../../Images/softwareDevelopment/bannerImg.png";

const SoftwareDevelopmentBanner = () => {
  return (
    <div className="container mx-auto px-5 lg:px-20 mt-20 lg:mt-24">
      <div className="grid gap grid-cols-1 lg:grid-cols-2 py-16 items-center justify-items-center">
        <div>
          <img src={Img} alt="" />
        </div>
        <div>
          <h1 className="lg:text-6xl text-4xl font-bold text-sky-700">
            Software <br />
            Development
            <br />
            Services
          </h1>
          <p className="text-2xl mt-5 font-medium">
            Partner With DevelopersThrill for Your Software <br /> Development
            Needs
          </p>
          <button className="btn rounded-full bg-transparent px-12 mt-10 hover:border-sky-800 hover:bg-transparent border-sky-800 bg-sky-800 hover:bg-sky-800">
            Contact us!
          </button>
        </div>
      </div>
    </div>
  );
};

export default SoftwareDevelopmentBanner;
