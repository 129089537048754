import React from "react";

const EnhancedDevelopment = () => {
  return (
    <div>
      <div className="lg:flex items-center justify-around mt-16 px-10 lg:px-0 my-10">
        <div className="lg:translate-x-[60px] text-left">
          <h1 className="text-blue-700 text-xl font-bold text-left">
            "SAAS" SUCCESS STORY
          </h1>
          <h1 className="text-3xl lg:text-4xl text-black mt-2 text-left">
            Enhanced Cloud Development
          </h1>
          <p className="text-slate-600 leading-9 text-left text-lg mt-4">
            The best approach of a SaaS application development, <br />
            that provides the possibility of easily buying and sell <br />
            premium media partners.
          </p>
          <div className="text-left mt-5 flex items-center justify-center">
            <div>
              <p className="text-lg">
                <i class="fa-solid fa-check text-lg mr-3 text-sky-400 font-bold"></i>{" "}
                Multi-availability zone
              </p>
              <p className="mt-3 text-lg">
                <i class="fa-solid fa-check text-lg mr-3 text-sky-400 font-bold"></i>{" "}
                Multi-availability zone
              </p>
            </div>
            <div className="ml-10">
              <p className="text-lg">
                <i class="fa-solid fa-check text-lg mr-3 text-sky-400 font-bold"></i>{" "}
                Multi-availability zone
              </p>
              <p className="mt-3 text-lg">
                <i class="fa-solid fa-check text-lg mr-3 text-sky-400 font-bold"></i>{" "}
                Multi-availability zone
              </p>
            </div>
          </div>
        </div>
        <div className="lg:w-[41%] bg-gradient-to-r from-[#DD9601] to-[#B46C43] p-6  w-full rounded-lg lg:translate-x-[80px] mt-8 lg:mt-0 pxy-5">
          <div className="p-4 text-white">
            <h1 className="text-xl font-medium"> STAFF AUGMENTATION</h1>
            <h1 className="text-4xl font-bold mt-4">BrandVerge</h1>
            <p className="mt-5">
              From Angular Js to React Js, BrandVerge wanted <br />
              to ensure a top-level UI/UX on mobile and web <br />
              for their end-users on its SaaS Application.
            </p>
            <button className="btn rounded-full bg-transparent border-2 px-12 mt-8 hover:border-white hover:bg-transparent border-white">
              Read Case Study
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnhancedDevelopment;
