import React from "react";

const Accelete = () => {
  return (
    <div className="bg-sky-100 pt-10">
      <div className="container mx-auto lg:px-20 px-5">
        <div className="grid gap-0 lg:gap-12 grid-cols-1 lg:grid-cols-2 py-8 items-center">
          <div>
            <div
              tabIndex={0}
              className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box drop-shadow-xl"
            >
              <div className="collapse-title text-xl font-medium">
                What does DevOps mean?
              </div>
              <div className="collapse-content">
                <p className="text-xl">
                  DevOps is a combination of cultural practices, philosophies
                  and tools implemented during the entire software development
                  lifecycle. The word “DevOps” is almost a short form for
                  “Development and Operations best practices that ensure
                  continuous delivery.”
                </p>
              </div>
            </div>

            <br />
            <div
              tabIndex={0}
              className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box drop-shadow-xl mt-4"
            >
              <div className="collapse-title text-xl font-medium">
                What are DevOps services?
              </div>
              <div className="collapse-content">
                <p className="text-xl">
                  DevOps services are a set of development and operation best
                  practices integrated on a server application to improve data
                  delivery. The aim of DevOps services is to ensure that every
                  action in the software delivery process is well implemented,
                  optimized, and trackable.
                </p>
              </div>
            </div>
            <br />

            <div
              tabIndex={0}
              className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box drop-shadow-xl mt-4"
            >
              <div className="collapse-title text-xl font-medium">
                What is a DevOps services company?
              </div>
              <div className="collapse-content">
                <p className="text-xl">
                  A DevOps Services company like DevelopersThrill takes charge
                  of the entire software development lifecycle of DevOps
                  implementation. A DevOps company combines continuous
                  integration and continuous delivery practices in the
                  infrastructure to enable more frequent code deployments.
                </p>
              </div>
            </div>

            <br />
            <div
              tabIndex={0}
              className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box drop-shadow-xl mt-4"
            >
              <div className="collapse-title text-xl font-medium">
                What is DevOps culture?
              </div>
              <div className="collapse-content">
                <p className="text-xl">
                  DevOps culture is a shared understanding among developers and
                  operations teams. DevOps isn’t a role; it is a set of cultural
                  practices on the web. DevOps culture is about working with
                  multidisciplinary teams that take collective accountability
                  for projects.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-10 lg:mt-0">
            <h1 className="md:text-sm lg:text-lg font-bold text-sky-700">
              DEVOPS FAQ
            </h1>
            <h1 className="md:text-3xl text-2xl lg:text-5xl font-medium text-black">
              Accelerate Your Project <br /> Delivery with DevOps
            </h1>
            <p className="text-xl mt-2">
              We often hear these questions about our DevOps Services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accelete;
