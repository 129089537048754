import React from "react";
import helpImg1 from "../../../../../Images/AWSManageService/howWeHelpImg1.png";
import helpImg2 from "../../../../../Images/AWSManageService/howWeHelpImg2.png";
import helpImg3 from "../../../../../Images/AWSManageService/howWeHelpImg3.png";
import helpImg4 from "../../../../../Images/AWSManageService/howWeHelpImg4.png";

const HowWeHelp = () => {
  return (
    <div className="container mx-auto px-5 lg:px-20 py-14">
      <div className="text-center">
        <h1 className="text-2xl text-blue-700 font-bold">HOW WE HELP</h1>
        <h1 className="text-3xl lg:text-4xl  font-bold mt-2">
          AWS Managed Process
        </h1>
        <p className="text-lg mt-3">
          Find out how <b>DevelopersThrill</b> Managed Process helps to{" "}
          <b>consolidate</b> your <b>critical data</b>.
        </p>
      </div>
      <div className="grid gap-0 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 my-10 justify-items-center">
        <div className="mt-4">
          <img className="w-60" src={helpImg1} alt="" />
          <p className="text-lg font-medium leading-6 mt-4">
            <a href="" className="text-sky-400">
              <i class="fa-solid fa-caret-right mr-2 "></i> Assessment & <br />{" "}
              Architecture
            </a>
          </p>
        </div>
        <div className="mt-4">
          <img className="w-60" src={helpImg2} alt="" />
          <p className="text-lg font-medium leading-6 mt-4">
            <a href="" className="text-sky-400">
              <i class="fa-solid fa-caret-right mr-2 "></i> Build Cloud Platform
            </a>
          </p>
        </div>
        <div className="mt-4">
          <img className="w-60" src={helpImg3} alt="" />
          <p className="text-lg font-medium leading-6 mt-4">
            <a href="" className="text-sky-400">
              <i class="fa-solid fa-caret-right mr-2 "></i> Innovation &
              Automation <br /> (DevOps)
            </a>
          </p>
        </div>
        <div className="mt-4 lg:translate-y-[-12px]">
          <img className="w-60" src={helpImg4} alt="" />
          <p className="text-lg font-medium leading-6 mt-4">
            <a href="" className="text-sky-400">
              <i class="fa-solid fa-caret-right mr-2 "></i> AWS Managed Services
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowWeHelp;
