import React from "react";
import BuildingEffective from "./Section/BuildingEffective/BuildingEffective";
import CaptureMarket from "./Section/CaptureMarket/CaptureMarket";
import EnhancedDevelopment from "./Section/EnhancedDevelopment/EnhancedDevelopment";
import IndustrySpecific from "./Section/IndustrySpecific/IndustrySpecific";
import ProspleEdutech from "./Section/ProspleEdutech/ProspleEdutech";
import SaasAppDevelopment from "./Section/SaasAppDevelopment/SaasAppDevelopment";
import SaasDevelopmentBanner from "./Section/SaasDevelopmentBanner/SaasDevelopmentBanner";
import SameTimeZone from "./Section/SameTimeZone/SameTimeZone";

const SaasApplicationDevelopment = () => {
  return (
    <div>
      <SaasDevelopmentBanner />
      <CaptureMarket />
      <ProspleEdutech />
      <IndustrySpecific />
      <SaasAppDevelopment />
      <EnhancedDevelopment />
      <BuildingEffective />
      <SameTimeZone />
    </div>
  );
};

export default SaasApplicationDevelopment;
