import React from "react";
import "./HowWeWorkBanner.css";
import BannerImg from "../../../../../Images/How-we-work/bannerImg.png";

const HowWeWorkBanner = () => {
  return (
    <div className="howWeWork-Bg lg:pt-24 mt-20 pb-10">
      <div className="container mx-auto lg:px-20 px-5">
        <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center ">
          <div className="flex justify-center mt-10 lg:mt-0">
            <img src={BannerImg} alt="" />
          </div>
          <div className="mt-10 lg:mt-0">
            <h1 className="lg:text-5xl text-4xl font-bold text-blue-900">
              How We Work
            </h1>
            <p className="text-lg mt-4  font-bold text-blue-900">
              Ease your DevOps and Software Development through <br /> effective
              collaboration and real-time business results.
            </p>
            <button className="btn rounded-full border-2 border-sky-700 bg-transparent text-sky-700 hover:bg-transparent hover:border-sky-700 px-12 mt-7 text-base ">
              Get in Touch!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWeWorkBanner;
