import React from "react";
import devOpsImg from "../../../../../Images/How-we-work/devopsImg.png";
import developerImg from "../../../../../Images/How-we-work/developmentImg.png";

const HowToWorkDevops = () => {
  return (
    <div className="container mx-auto lg:px-20 px-5">
      <div className="text-center mt-12 mb-10">
        <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
          DEVOPS
        </h1>
        <h1 className="lg:text-4xl text-2xl text-black md:text-3xl font-bold mt-4">
          Your best ally for Growing Your Company
        </h1>
      </div>
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 mb-6 items-center justify-center">
        <div class="card lg:w-4/5  w-full bg-base-100 shadow-xl py-4 mt-6">
          <div className="flex justify-center">
            <img src={devOpsImg} />
          </div>

          <div class=" text-center my-4 px-8">
            <h2 class="font-bold text-blue-900 text-3xl">
              <a href="">DevOps</a>
            </h2>
            <p className=" text-sm text-slate-700 mt-2">
              Serverless Modernization, Microservices and Docker Architecture,
              DevOps Automation, Kubernetes Clustering, CI/CD workflows, Cloud
              Security, SOC2 and HIPAA Compliance.
            </p>
          </div>
        </div>
        <div class="card lg:w-4/5  w-full bg-base-100 shadow-xl py-4 mt-6">
          <div className="flex justify-center">
            <img src={developerImg} />
          </div>

          <div class=" text-center my-4 px-8">
            <h2 class="font-bold text-blue-900 text-3xl">
              <a href="">Development</a>
            </h2>
            <p className=" text-sm text-slate-700 mt-2">
              UX/UI Software development, API Integrations, Backend development,
              Frontend development (Angular, React, Value, Js), E-Comerce
              Development, etc.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToWorkDevops;
