import React from "react";
import LearnMoreImg1 from "../../../../../Images/Learn-more-1.jpg";
import LearnMoreImg2 from "../../../../../Images/Learn-more-2.jpg";
import LearnMoreImg3 from "../../../../../Images/Learn-more-3.jpg";

const LearnMore = () => {
  return (
    <div className="bg-sky-100 pt-10">
      <div className="container mx-auto lg:px-20 px-5">
        <div className="text-center my-12">
          <h1 className="md:text-lg lg:text-2xl text-xl font-bold text-sky-700">
            LEARN MORE
          </h1>
          <h1 className="lg:text-4xl text-2xl text-black md:text-3xl font-bold mt-4">
            About DevelopersThrill and DevOps Culture
          </h1>
        </div>
        <div className="grid gap-0 lg:gap-12 grid-cols-1 lg:grid-cols-3">
          <div className="mt-12 lg:mt-0">
            <a
              href=""
              className="text-2xl text-blue-900 font-medium inline-block lg:mb-8 ml-3"
            >
              Webinars
            </a>
            <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-xl mt-10 lg:mt-0">
              <figure>
                <img src={LearnMoreImg1} alt="Shoes" />
              </figure>
              <div className="p-4">
                <h1 className="md:text-xl lg:text-2xl font-medium text-black mt-2">
                  <a href="">Multi-Tenant Database Architecture</a>
                </h1>
                <br />
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Aliquid, a? Provident ratione fugiat maiores quas consequatur
                  laboriosam aspernatur.
                </p>
                <div className="text-center">
                  <button className="btn rounded-full border-2 border-sky-700 bg-transparent text-sky-700 hover:bg-transparent hover:border-sky-700 px-8 mt-7 text-base ">
                    Watch video
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 lg:mt-0">
            <a
              href=""
              className="text-2xl text-blue-900 font-medium inline-block lg:mb-8 ml-3"
            >
              Blogs
            </a>
            <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-xl mt-10 lg:mt-0">
              <figure>
                <img src={LearnMoreImg2} alt="Shoes" />
              </figure>
              <div className="p-4">
                <h1 className="md:text-xl lg:text-2xl font-medium text-black mt-2">
                  <a href="">
                    Serverless Database: Everything you need to know
                  </a>
                </h1>
                <br />
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Aliquid, a? Provident ratione fugiat
                </p>
                <div className="text-center">
                  <button className="btn rounded-full border-2 border-sky-700 bg-transparent text-sky-700 hover:bg-transparent hover:border-sky-700 px-8 mt-7 text-base ">
                    Watch video
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 lg:mt-0">
            <a
              href=""
              className="text-2xl text-blue-900 font-medium inline-block  lg:mb-8 ml-3"
            >
              Ebook
            </a>
            <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-xl mt-10 lg:mt-0">
              <figure>
                <img src={LearnMoreImg3} alt="Shoes" />
              </figure>
              <div className="p-4">
                <h1 className="md:text-xl lg:text-2xl font-medium text-black mt-2">
                  <a href="">Multi-Tenant SaaS Application on AWS</a>
                </h1>
                <br />
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Aliquid, a? Provident ratione fugiat maiores quas consequatur
                  laboriosam aspernatur.
                </p>
                <div className="text-center">
                  <button className="btn rounded-full border-2 border-sky-700 bg-transparent text-sky-700 hover:bg-transparent hover:border-sky-700 px-8 mt-7 text-base ">
                    Watch video
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:flex items-center lg:justify-center lg:justify-evenly text-center py-20">
          <h1 className="lg:text-4xl text-2xl font-medium">
            Top DevOps Services and IT Talent for <br /> Growing North American
            Companies
          </h1>
          <button className="btn rounded-full border-2 border-sky-700 bg-transparent text-sky-700 hover:bg-transparent hover:border-sky-700 px-8 mt-7 text-base ">
            Contact us
          </button>
        </div>
      </div>
    </div>
  );
};

export default LearnMore;
