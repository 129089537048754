import React from "react";
import ContactImg from "../../../../../Images/about/contact-img.png";

const OurNearshoreFramework = () => {
  return (
    <div className="container mx-auto lg:px-20 px-6 my-10">
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center">
        <div className=" mt-6 lg:mt-0">
          <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
            OUR NEARSHORE FRAMEWORK
          </h1>
          <h1 className="lg:text-4xl text-2xl text-black md:text-2xl font-medium mt-4">
            Software Development Teams in <br />
            North America
          </h1>
          <p className="text-lg mt-6 text-slate-600">
            Outsource your IT projects to a nearshore team. Avoid delays and
            <br />
            ensure a smoother, quicker process.
          </p>
          <h1 className="md:text-lg text-xl lg:text-2xl text-sky-700 mt-4">
            Nearshoring Benefits:
          </h1>
          <ul className="ml-5 mt-2">
            <li>
              <i class="fa-solid fa-caret-right pr-2 text-blue-700"></i>Same
              time zone
            </li>
            <li>
              <i class="fa-solid fa-caret-right pr-2 text-blue-700"></i>
              Cost-effective
            </li>
            <li>
              <i class="fa-solid fa-caret-right pr-2 text-blue-700"></i>
              Culturally aligned
            </li>
            <li>
              <i class="fa-solid fa-caret-right pr-2 text-blue-700"></i>Flexible
            </li>
          </ul>
          <p className="text-lg mt-6 text-slate-600">
            DevelopersThrill Nearshore Framework helps us deliver top-level
            service which
            <br />
            ensures your satisfaction.
          </p>
          <button className="btn rounded-full bg-transparent border-4 px-12 mt-6 bg-blue-700 hover:border-blue-700 hover:bg-blue-700 border-blue-700 text-white">
            Find More
          </button>
        </div>
        <div>
          <img src={ContactImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default OurNearshoreFramework;
