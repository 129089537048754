import React from "react";

const DevOpsBenefits = () => {
  return (
    <div className="container mx-auto px-5 lg:px-20 ">
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 my-10 items-center justify-items-center lg:pb-12">
        <div>
          <p className="text-base text-xl mt-3">
            <i class="fa-regular fa-circle-check mr-2 text-lg text-sky-600"></i>
            25x delivery new code.
          </p>
          <p className="text-base text-xl mt-3">
            <i class="fa-regular fa-circle-check mr-2 text-lg text-sky-600"></i>
            10x reduces development costs.
          </p>
          <p className="text-base text-xl mt-3">
            <i class="fa-regular fa-circle-check mr-2 text-lg text-sky-600"></i>
            80% decrease in outages and downtimes.
          </p>
          <p className="text-base text-xl mt-3">
            <i class="fa-regular fa-circle-check mr-2 text-lg text-sky-600"></i>
            15% speed to market.
          </p>
          <p className="text-base text-xl mt-3">
            <i class="fa-regular fa-circle-check mr-2 text-lg text-sky-600"></i>
            100x deploy to production.
          </p>
          <p className="text-base text-xl mt-3">
            <i class="fa-regular fa-circle-check mr-2 text-lg text-sky-600"></i>
            10x less time restoring service failure.
          </p>
        </div>
        <div>
          <h1 className="text-2xl text-bold text-blue-700">DEVOPS BENEFITS</h1>
          <h1 className="lg:text-4xl font-medium text-3xl">
            How Can DevOps <br />
            Outsourcing Help You?
          </h1>
          <button className="btn rounded-full bg-transparent border-2 px-12 mt-4 hover:border-sky-700 hover:bg-transparent border-sky-700 text-blue-700">
            CONTACT US ABOUT DEVOPS OUTSOURCING
          </button>
        </div>
      </div>
    </div>
  );
};

export default DevOpsBenefits;
