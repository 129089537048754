import React from "react";
import "./SupportAgileSoftware.css";
import SupportImg1 from "../../../../../Images/softwareDevelopment/supportAgile1.png";
import SupportImg2 from "../../../../../Images/softwareDevelopment/supportAgile2.png";
import SupportImg3 from "../../../../../Images/softwareDevelopment/supportAgile3.png";
const SupportAgileSoftware = () => {
  return (
    <div className="support-agile-container py-16">
      <div className="container mx-auto lg:px-20 px-5">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-blue-700">
            ENGAGEMENT MODELS TO SUIT YOUR NEEDS
          </h1>
          <h1 className="lg:text-4xl text-3xl text-black mt-1 font-bold">
            3 Ways We Support Agile Software Development
          </h1>
        </div>
        <div className="grid gap-0 grid-cols-1 lg:grid-cols-3 justify-items-center mt-4">
          <div class="card lg:w-80  w-full hover:shadow-2xl bg-base-100 shadow-xl py-4 mt-6">
            <div className="flex justify-center">
              <img src={SupportImg1} />
            </div>

            <div class=" text-center my-4 px-8">
              <h2 class="font-medium text-blue-900 text-2xl">
                Staff Augmentation
              </h2>
              <p className=" text-base text-slate-700 mt-2">
                Integrate our engineers with your
                <br />
                team to work cooperatively on your
                <br />
                DevOps tasks? We got you covered.
                <br />
                collaborate as part of
              </p>
              <button className="btn rounded-full bg-transparent border-2 px-10 mt-8 text-blue-800 hover:border-sky-600 hover:bg-transparent border-sky-600">
                Learn More
              </button>
            </div>
          </div>
          <div class="card lg:w-80  w-full hover:shadow-2xl bg-base-100 shadow-xl py-4 mt-6">
            <div className="flex justify-center">
              <img src={SupportImg2} />
            </div>

            <div class=" text-center my-4 px-8">
              <h2 class="font-medium text-blue-900 text-2xl">
                Dedicated Teams
              </h2>
              <p className=" text-base text-slate-700 mt-2">
                Integrate our engineers with your
                <br />
                team to work cooperatively on your
                <br />
                DevOps tasks? We got you covered.
                <br />
                collaborate as part of
              </p>
              <button className="btn rounded-full bg-transparent border-2 px-10 mt-8 text-blue-800 hover:border-sky-600 hover:bg-transparent border-sky-600">
                Learn More
              </button>
            </div>
          </div>
          <div class="card lg:w-80  w-full hover:shadow-2xl bg-base-100 shadow-xl py-4 mt-6">
            <div className="flex justify-center">
              <img src={SupportImg1} />
            </div>

            <div class=" text-center my-4 px-8">
              <h2 class="font-medium text-blue-900 text-2xl">
                Project Based Model
              </h2>
              <p className=" text-base text-slate-700 mt-2">
                Integrate our engineers with your
                <br />
                team to work cooperatively on your
                <br />
                DevOps tasks? We got you covered.
                <br />
                collaborate as part of
              </p>
              <button className="btn rounded-full bg-transparent border-2 px-10 mt-8 text-blue-800 hover:border-sky-600 hover:bg-transparent border-sky-600">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportAgileSoftware;
