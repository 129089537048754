import React from "react";
import Logo from "../../../../Images/Logo/logo.png";
import awsImg from "../../../../Images/footer-amazon.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  const time = new Date();
  const year = time.getFullYear();
  return (
    <footer>
      <div className="bg-slate-900">
        <div className="container mx-auto lg:px-20 px-5 text-white py-16">
          <div className="text-center">
            <h1 className="text-xl font-medium text-[#FF6700]">
              Our Newsletter
            </h1>
            <form action="">
              <div className="lg:flex items-center justify-center mt-4">
                <div>
                  <input
                    type="text"
                    placeholder="Name"
                    class="input  bg-slate-700 rounded-none w-[360px]"
                  />
                </div>
                <div className="lg:ml-4 lg:mt-0 mt-4">
                  <input
                    type="text"
                    placeholder="Email"
                    class="input  bg-slate-700 rounded-none w-[360px]"
                  />
                </div>
              </div>
              <div>
                <button className="btn bg-transparent border-[#FF6700] border-2 mt-4 px-20 hover:bg-[#FF6700] hover:border-[#FF6700]">
                  {" "}
                  send
                </button>
              </div>
            </form>
          </div>
          <div className="mt-10">
            <div className="lg:flex justify-between  mt-5">
              <div>
                <img src={Logo} alt="" className="w-[14rem]" />
                <p className="text-slate-400 mt-6">
                  DevelopersThrill & Software Development is a premium Cloud and{" "}
                  <br />
                  <a className="hover:text-[#ff6700] text-blue-700" href="">
                    {" "}
                    Nearshore Solution Provider
                  </a>{" "}
                  helping companies of all sizes in <br />
                  Healthcare, Fintech and MarTech with superior tech solutions
                  <br />
                  focused on Cloud Migrations, Continuous Delivery, DevSecOps,
                  Micro
                  <br />
                  services and AWS Managed services
                </p>
                <div>
                  <ul className="flex items-center mt-6">
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-facebook text-2xl"></i>
                      </a>
                    </li>
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-twitter text-2xl"></i>
                      </a>
                    </li>
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-youtube text-2xl"></i>
                      </a>
                    </li>
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-linkedin text-2xl"></i>
                      </a>
                    </li>
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-spotify text-2xl"></i>
                      </a>
                    </li>
                    <li className="mr-5">
                      <a href="" class="btn btn-circle hover:bg-sky-600">
                        <i class="fa-brands fa-instagram text-2xl"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex justify-between lg:w-96 mt-6 lg:mt-0">
                <div className="lg:mr-10px">
                  <h1 className="text-xl font-bold">Solutions</h1>
                  <ul className="text-slate-400	">
                    <li className="mt-2">
                      <a className="hover:text-blue-700" href="">
                        DevOps for Fintech
                      </a>
                    </li>
                    <li className="mt-2">
                      <a className="hover:text-blue-700" href="">
                        HIPAA compliance
                      </a>
                    </li>
                    <li className="mt-2">
                      <a className="hover:text-blue-700" href="">
                        AWS Cloud assessment
                      </a>
                    </li>
                    <li className="mt-2">
                      <Link className="hover:text-blue-700" to="/how-we-work">
                        How We Work
                      </Link>
                    </li>
                    <li className="mt-2">
                      <a className="hover:text-blue-700" href="">
                        SaaS development
                      </a>
                    </li>
                  </ul>
                  <img className="w-44 mt-5" src={awsImg} alt="" />
                </div>
                <div>
                  <h1 className="text-xl font-bold">Services</h1>
                  <ul className="text-slate-400	">
                    <li className="mt-2">
                      <a className="hover:text-blue-700" href="">
                        DevOps for Fintech
                      </a>
                    </li>
                    <li className="mt-2">
                      <a className="hover:text-blue-700" href="">
                        HIPAA compliance
                      </a>
                    </li>
                    <li className="mt-2">
                      <a className="hover:text-blue-700" href="">
                        AWS Cloud assessment
                      </a>
                    </li>
                    <li className="mt-2">
                      <Link className="hover:text-blue-700" to="/how-we-work">
                        How We Work
                      </Link>
                    </li>
                    <li className="mt-2">
                      <a className="hover:text-blue-700" href="">
                        SaaS development
                      </a>
                    </li>
                  </ul>
                  <img className="w-44 mt-5" src={awsImg} alt="" />
                </div>
              </div>
              <div className="mt-6 lg:mt-0">
                <h1 className="text-xl font-bold">Location</h1>
                <ul className="text-slate-400	">
                  <li className="mt-2">
                    <p>Dhata, Bangladesh</p>
                    <p className="text-slate-600">+880 1303000252 </p>
                  </li>
                  {/* <li className="mt-2">
                    <p>Monterrey, México</p>
                    <p className="text-slate-600">+52 (811) 255-6467</p>
                  </li>
                  <li className="mt-2">
                    <p>Los Angeles, USA</p>
                    <p className="text-slate-600">+1 (512) 487-7554</p>
                  </li> */}
                </ul>
                <img className="w-44 mt-4" src={awsImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black">
        <div className="container mx-auto lg:px-20 px-5 py-6 lg:flex items-center justify-between text-center">
          <p className="text-slate-400">
            &copy; DevelopersThrill & Software Development | {year} | All Rights
            Reserved
          </p>
          <div className="flex items-center text-slate-400 text-center">
            <p className="mr-2">
              {" "}
              <Link onClick={scrollTop} to="/privacy-policy">
                Privacy policy
              </Link>
            </p>{" "}
            <p className="mr-2">
              <Link onClick={scrollTop} to="/term-of-service">
                Terms of Service
              </Link>
            </p>{" "}
            <p>
              <Link onClick={scrollTop} to="/cookie-policy">
                Cookies policy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
