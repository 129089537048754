import React from "react";
import BlogsPost from "../HowToWork/Section/BlogsPost/BlogsPost";
import BrandVerge from "./Section/BrandVerge/BrandVerge";
import ContactUs from "./Section/ContactUs/ContactUs";
import OurNearshoreFramework from "./Section/OurNearshoreFramework/OurNearshoreFramework";
import OutsourceSoftware from "./Section/OutsourceSoftware/OutsourceSoftware";
import SkillSoftwareDev from "./Section/SkillSoftwareDev/SkillSoftwareDev";
import SoftwareDevelopmentBanner from "./Section/SoftwareDevelopmentBanner/SoftwareDevelopmentBanner";
import SoftwareService from "./Section/SoftwareService/SoftwareService";
import SupportAgileSoftware from "./Section/SupportAgileSoftware/SupportAgileSoftware";
import WhyIsIt from "./Section/WhyIsIt/WhyIsIt";

const SoftwareDevelopment = () => {
  return (
    <div>
      <SoftwareDevelopmentBanner />
      <WhyIsIt />
      <SoftwareService />
      <SupportAgileSoftware />
      <OurNearshoreFramework />
      <SkillSoftwareDev />
      <BrandVerge />
      <BlogsPost />
      <OutsourceSoftware />
      <ContactUs />
    </div>
  );
};

export default SoftwareDevelopment;
