import React from "react";
import "./IndustryLeadingService.css";
import industryLeadingIcon1 from "../../../../../Images/DebOpsOutsourcing/industryLeadingIcon1.png";
import industryLeadingIcon2 from "../../../../../Images/DebOpsOutsourcing/industryLeadingIcon2.png";
import industryLeadingIcon3 from "../../../../../Images/DebOpsOutsourcing/industryLeadingIcon3.png";

const IndustryLeadingService = () => {
  return (
    <div className="industryLeading-container">
      <div className="container mx-auto px-5 lg:px-20 text-white py-12">
        <div className="text-center">
          <h1 className="text-3xl font-medium ">
            Industry-Leading Services and Support
          </h1>
          <p className="text-lg mt-4">
            DevelopersThrill is your ideal technology partner. We offer a full
            spectrum of cloud services in our agency, from DevOps to Migration
            to <br />
            Automation. Count on us to manage your web applications.
          </p>
        </div>
        <div className="grid gap-0 grid-cols-1 lg:grid-cols-3 items-center justify-items-center py-16 ">
          <div className="flex items-center ">
            <div className="">
              <h1 className="lg:text-5xl font-medium  tracking-wider">1000+</h1>
              <p className="text-base text-slate-200">
                Successful AWS <br />
                Migrations.
              </p>
            </div>
            <img className="w-24" src={industryLeadingIcon1} alt="" />
            <hr class="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700" />
          </div>
          <div className="flex items-center">
            <div className="">
              <h1 className="lg:text-5xl font-medium  tracking-wider">100+</h1>
              <p className="text-base text-slate-200">
                Successful AWS <br />
                Migrations.
              </p>
            </div>
            <img className="w-24" src={industryLeadingIcon2} alt="" />
          </div>
          <div className="flex items-center">
            <div className="">
              <h1 className="lg:text-5xl font-medium  tracking-wider">300+</h1>
              <p className="text-base text-slate-200">
                Successful AWS <br />
                Migrations.
              </p>
            </div>
            <img className="w-24" src={industryLeadingIcon3} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryLeadingService;
