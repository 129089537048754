import React from "react";
import IndustryImg1 from "../../../../../Images/sassApplicationDevelopment/industryImg1.png";
import IndustryImg2 from "../../../../../Images/sassApplicationDevelopment/industryImg2.png";
import IndustryImg3 from "../../../../../Images/sassApplicationDevelopment/industryImg3.png";
import IndustryImg4 from "../../../../../Images/sassApplicationDevelopment/industryImg4.png";
import IndustryImg5 from "../../../../../Images/sassApplicationDevelopment/industryImg5.png";
import IndustryImg6 from "../../../../../Images/sassApplicationDevelopment/industryImg6.png";

const IndustrySpecific = () => {
  return (
    <div className="container mx-auto px-5 lg:px-20 py-10">
      <div className="text-center">
        <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
          INDUSTRY SPECIFIC SAAS SOLUTIONS
        </h1>
        <h1 className="lg:text-5xl leadign-8 text-3xl text-black md:text-3xl font-medium mt-2">
          Because each industry has its own
          <br /> challenges
        </h1>
      </div>
      <div className="grid gap-0 lg:gap-8 lg:grid-cols-3 grid-cols-1">
        <div>
          <div className="lg:h-80 shadow-lg p-4 border-2 border-sky-300 hover:border-blue-700 duration-200 hover:shadow-2xl flex items-center justify-center mt-4">
            <div>
              {" "}
              <div className="flex items-center justify-center">
                <img src={IndustryImg1} className="w-24 mr-2" alt="" />
                <h1 className="text-2xl text-blue-700">AI ML SaaS App</h1>
              </div>
              <p className="mt-5 text-black flex justify-center items-center">
                Building your AI or Machine Learning <br />
                based solution in form of a SaaS <br />
                Application makes it accessible, <br />
                scalable and easier to monetize
              </p>
            </div>
          </div>
          <div className="lg:h-80 shadow-lg p-4 border-2 border-sky-300 hover:border-blue-700 duration-200 hover:shadow-2xl flex items-center justify-center mt-4">
            <div>
              {" "}
              <div className="flex items-center justify-center">
                <img src={IndustryImg2} className="w-24 mr-2" alt="" />
                <h1 className="text-2xl text-blue-700">
                  SaaS Platform for <br /> Marketing <br /> Automation
                </h1>
              </div>
              <p className="mt-5 text-black flex justify-center items-center">
                World’s leading Marketing tools, <br />
                CRMs and communication platforms
                <br />
                are built on a strong foundation of
                <br />
                SaaS. Convert your marketing
                <br />
                wizardry into automated chains and <br />
                processes in form of a SaaS <br />
                Application Development.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="lg:h-80 shadow-lg p-4 border-2 border-sky-300 hover:border-blue-700 duration-200 hover:shadow-2xl flex items-center justify-center mt-4">
            <div>
              {" "}
              <div className="flex items-center justify-center">
                <img src={IndustryImg3} className="w-24 mr-2" alt="" />
                <h1 className="text-2xl text-blue-700">
                  FinTech SaaS <br /> Application
                </h1>
              </div>
              <p className="mt-5 text-black flex justify-center items-center">
                Building your AI or Machine Learning <br />
                based solution in form of a SaaS <br />
                Application makes it accessible, <br />
                scalable and easier to monetize
              </p>
            </div>
          </div>
          <div className="lg:h-80 shadow-lg p-4 border-2 border-sky-300 hover:border-blue-700 duration-200 hover:shadow-2xl flex items-center justify-center mt-4">
            <div>
              {" "}
              <div className="flex items-center justify-center">
                <img src={IndustryImg2} className="w-24 mr-2" alt="" />
                <h1 className="text-2xl text-blue-700">
                  Healthcare & <br /> Patient Record <br /> SaaS Solution​
                </h1>
              </div>
              <p className="mt-5 text-black flex justify-center items-center">
                World’s leading Marketing tools, <br />
                CRMs and communication platforms
                <br />
                are built on a strong foundation of
                <br />
                SaaS. Convert your marketing
                <br />
                wizardry into automated chains and <br />
                processes in form of a SaaS <br />
                Application Development.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="lg:h-80 shadow-lg p-4 border-2 border-sky-300 hover:border-blue-700 duration-200 hover:shadow-2xl flex items-center justify-center mt-4">
            <div>
              {" "}
              <div className="flex items-center justify-center">
                <img src={IndustryImg5} className="w-24 mr-2" alt="" />
                <h1 className="text-2xl text-blue-700">
                  Enterprise <br /> Management <br />
                  SaaS Solution
                </h1>
              </div>
              <p className="mt-5 text-black flex justify-center items-center">
                Building your AI or Machine Learning <br />
                based solution in form of a SaaS <br />
                Application makes it accessible, <br />
                scalable and easier to monetize
              </p>
            </div>
          </div>
          <div className="lg:h-80 shadow-lg p-4 border-2 border-sky-300 hover:border-blue-700 duration-200 hover:shadow-2xl flex items-center justify-center mt-4">
            <div>
              {" "}
              <div className="flex items-center justify-center">
                <img src={IndustryImg6} className="w-24 mr-2" alt="" />
                <h1 className="text-2xl text-blue-700">
                  IoT and
                  <br /> Wearable Tech <br /> Applications​
                </h1>
              </div>
              <p className="mt-5 text-black flex justify-center items-center">
                World’s leading Marketing tools, <br />
                CRMs and communication platforms
                <br />
                are built on a strong foundation of
                <br />
                SaaS. Convert your marketing
                <br />
                wizardry into automated chains and <br />
                processes in form of a SaaS <br />
                Application Development.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <button className="btn rounded-full bg-transparent border-2 px-12 mt-8 hover:border-blue-700 hover:bg-transparent border-blue-700 text-blue-700">
          Get A Quote
        </button>
      </div>
    </div>
  );
};

export default IndustrySpecific;
