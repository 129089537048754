import React from "react";
import BlogsImg1 from "../../../../../Images/How-we-work/blogsImg1.jpg";
import BlogsImg2 from "../../../../../Images/How-we-work/blogsImg2.jpg";
import BlogsImg3 from "../../../../../Images/How-we-work/blogsImg3.jpg";
import BlogsImg4 from "../../../../../Images/How-we-work/blogsImg4.jpg";
import BlogsImg5 from "../../../../../Images/How-we-work/blogsImg5.jpg";
import BlogsImg6 from "../../../../../Images/How-we-work/blogsImg6.jpg";

const BlogsPost = () => {
  return (
    <div className="container mx-auto px-5 lg:px-20">
      <div className="text-center mt-12 mb-14">
        <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
          BLOG POSTS
        </h1>
        <h1 className="lg:text-4xl text-2xl text-black md:text-3xl font-semibold mt-4">
          DevOps knowledge for everyone
        </h1>
      </div>
      <div className="mt-10 grid gap-0  grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center pb-14">
        <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-2xl mt-10 lg:mt-0">
          <figure className="group relative">
            <img src={BlogsImg1} className="w-full" />
            <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-black opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
              <a class="mt-5 px-8 py-3  text-white" href="#">
                <i class="fa-solid fa-arrow-right text-2xl"></i>
              </a>
            </div>
          </figure>
          <div className="p-4">
            <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
              <a className=" hover:text-blue-700 duration-200" href="">
                IT Staff Augmentation Services: A way
              </a>
            </h1>

            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid,
              a? Provident ratione fugiat maiores quas...
            </p>
            <div className="mt-4">
              <a className="text-sm text-[#FF6700]" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-2xl mt-10 lg:mt-0">
          <figure className="group relative">
            <img src={BlogsImg2} className="w-full" />
            <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-black opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
              <a class="mt-5 px-8 py-3  text-white" href="#">
                <i class="fa-solid fa-arrow-right text-2xl"></i>
              </a>
            </div>
          </figure>
          <div className="p-4">
            <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
              <a className=" hover:text-blue-700 duration-200" href="">
                6 Reasons your DevOps team in
              </a>
            </h1>

            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid,
              a? Provident ratione fugiat maiores quas...
            </p>
            <div className="mt-4">
              <a className="text-sm text-[#FF6700]" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-2xl mt-10 lg:mt-0">
          <figure className="group relative">
            <img src={BlogsImg3} className="w-full" />
            <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-black opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
              <a class="mt-5 px-8 py-3  text-white" href="#">
                <i class="fa-solid fa-arrow-right text-2xl"></i>
              </a>
            </div>
          </figure>
          <div className="p-4">
            <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
              <a className=" hover:text-blue-700 duration-200" href="">
                Why Remote Teams are important and
              </a>
            </h1>

            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid,
              a? Provident ratione fugiat maiores quas...
            </p>
            <div className="mt-4">
              <a className="text-sm text-[#FF6700]" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-2xl mt-10 ">
          <figure className="group relative">
            <img src={BlogsImg4} className="w-full" />
            <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-black opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
              <a class="mt-5 px-8 py-3  text-white" href="#">
                <i class="fa-solid fa-arrow-right text-2xl"></i>
              </a>
            </div>
          </figure>
          <div className="p-4">
            <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
              <a className=" hover:text-blue-700 duration-200" href="">
                How to upload files to Amazon
              </a>
            </h1>

            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid,
              a? Provident ratione fugiat maiores quas...
            </p>
            <div className="mt-4">
              <a className="text-sm text-[#FF6700]" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-2xl mt-10">
          <figure className="group relative">
            <img src={BlogsImg5} className="w-full" />
            <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-black opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
              <a class="mt-5 px-8 py-3  text-white" href="#">
                <i class="fa-solid fa-arrow-right text-2xl"></i>
              </a>
            </div>
          </figure>
          <div className="p-4">
            <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
              <a className=" hover:text-blue-700 duration-200" href="">
                Apache and Nginx Multi Tenant to
              </a>
            </h1>

            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid,
              a? Provident ratione fugiat maiores quas...
            </p>
            <div className="mt-4">
              <a className="text-sm text-[#FF6700]" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-2xl mt-10 ">
          <figure className="group relative">
            <img src={BlogsImg6} className="w-full" />
            <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-black opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
              <a class="mt-5 px-8 py-3  text-white" href="#">
                <i class="fa-solid fa-arrow-right text-2xl"></i>
              </a>
            </div>
          </figure>
          <div className="p-4">
            <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
              <a className=" hover:text-blue-700 duration-200" href="">
                Single Tenant vs Multi Tenant: SaaS
              </a>
            </h1>

            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid,
              a? Provident ratione fugiat maiores quas...
            </p>
            <div className="mt-4">
              <a className="text-sm text-[#FF6700]" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsPost;
