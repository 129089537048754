import React from "react";

const OutsourceSoftware = () => {
  return (
    <div className="bg-sky-100 pt-10">
      <div className="container mx-auto lg:px-20 px-5">
        <div className="grid gap-0 lg:gap-12 grid-cols-1 lg:grid-cols-2 py-8 items-center">
          <div>
            <div
              tabIndex={0}
              className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box drop-shadow-xl"
            >
              <div className="collapse-title text-xl font-medium">
                What Software Development Services does DevelopersThrill offer?
              </div>
              <div className="collapse-content">
                <p className="text-xl">
                  We deliver a variety of nearshore software development
                  services: custom software development, SaaS app development,
                  serverless development, Web design, and UI/UX. Each is
                  dedicated to helping you accomplish your business goals.
                </p>
              </div>
            </div>

            <br />
            <div
              tabIndex={0}
              className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box drop-shadow-xl mt-4"
            >
              <div className="collapse-title text-xl font-medium">
                What is Nearshore Software Development Outsourcing?
              </div>
              <div className="collapse-content">
                <p className="text-xl">
                  Nearshore Software Outsourcing allows you to delegate IT
                  projects to a team that is located near yours. Nearshoring
                  typically reduces problems related to the time zone and
                  language. <br /> <br />
                  Outsourcing to a nearshore IT team saves time and money and
                  helps you deliver the best solutions to your customers.
                </p>
              </div>
            </div>
            <br />

            <div
              tabIndex={0}
              className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box drop-shadow-xl mt-4"
            >
              <div className="collapse-title text-xl font-medium">
                How does DevelopersThrill offer software development services?
              </div>
              <div className="collapse-content">
                <p className="text-xl">
                  DevelopersThrill works in three different ways, letting you
                  choose the model that best fits your business needs:
                </p>
                <ul className="list-[decimal] ml-8 mt-3">
                  <li className="mt-3">
                    <b>Staff augmentation.</b> Our software developers work{" "}
                    <br />
                    as part of your team.
                  </li>
                  <li className="mt-3">
                    <b>Dedicated teams.</b> Outsource to a complete team of{" "}
                    <br />
                    DevelopersThrill employees.
                  </li>
                  <li className="mt-3">
                    Project-based work. Hire the resources you need on a <br />
                    project-by-project basis.
                  </li>
                </ul>
              </div>
            </div>

            <br />
            <div
              tabIndex={0}
              className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box drop-shadow-xl mt-4"
            >
              <div className="collapse-title text-xl font-medium">
                What are the benefits of Software Development Outsourcing?
              </div>
              <div className="collapse-content">
                <p className="text-xl">
                  Outsourcing your software development puts you in touch with
                  the talent—and the expertise—that your project requires.
                  You’ll reduce costs and save time while optimizing the
                  software development process.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-10 lg:mt-0">
            <h1 className="md:text-sm lg:text-lg font-bold text-sky-700">
              FAQ
            </h1>
            <h1 className="md:text-3xl text-2xl lg:text-4xl font-medium text-black">
              Outsource Your Software <br />
              Development
            </h1>
            <p className="text-xl mt-2">
              We often hear these questions about our nearshore <br />
              Software Development Services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutsourceSoftware;
