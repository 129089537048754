import React from "react";
import NotFoundImg from "../../../Images/NotFound/NotFound.png";

const NotFound = () => {
  return (
    <div className="flex items-center justify-center lg:mt-24 mt-20">
      <img width="90%" src={NotFoundImg} alt="" />
    </div>
  );
};

export default NotFound;
