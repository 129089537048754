import React from "react";
import "./HireOutsourceDevOps.css";
import hireOutsourceIcon1 from "../../../../../Images/DebOpsOutsourcing/hireOutsourceIcon1.png";
import hireOutsourceIcon2 from "../../../../../Images/DebOpsOutsourcing/hireOutsourceIcon2.png";
import hireOutsourceIcon3 from "../../../../../Images/DebOpsOutsourcing/hireOutsourceIcon3.png";
const HireOutsourceDevOps = () => {
  return (
    <div className="hireOutsource-container">
      <div className="container mx-auto lg:px-20 px-5 py-10">
        <h1 className="text-center text-white font-bold lg:text-3xl text-2xl">
          HIRE NEARSHORE OUTSOURCED DEVOPS SERVICES
        </h1>
        <div className="grid gap-0 lg:gap-8 lg:grid-cols-3 grid-cols-1 items-center justify-items-center mt-8">
          <div className="text-center text-white p-4 border border-white rounded-lg hover:rounded-none hover:border-blue-700 duration-200 mt-4">
            <div className="flex justify-center">
              <img src={hireOutsourceIcon1} alt="" />
            </div>
            <h1 className="text-2xl font-bold mt-3">Dedicated Teams</h1>
            <p className="text-lg text-slate-200 mt-3">
              DevelopersThrill can provide dedicated DevOps <br />
              engineers for infrastructure planning, <br /> testing, and
              development. Build your
              <br />
              own managed dedicated DevOps team, <br />
              with engineers of any expertise and <br />
              seniority.
            </p>
          </div>
          <div className="text-center text-white p-4 border border-white rounded-lg hover:rounded-none hover:border-blue-700 duration-200 mt-4">
            <div className="flex justify-center">
              <img src={hireOutsourceIcon2} alt="" />
            </div>
            <h1 className="text-2xl font-bold mt-3">Fixed-Price Model</h1>
            <p className="text-lg text-slate-200 mt-3">
              DevelopersThrill can provide dedicated DevOps <br />
              engineers for infrastructure planning, <br /> testing, and
              development. Build your
              <br />
              own managed dedicated DevOps team, <br />
              with engineers of any expertise and <br />
              seniority.
            </p>
          </div>
          <div className="text-center text-white p-4 border border-white rounded-lg hover:rounded-none hover:border-blue-700 duration-200 mt-4">
            <div className="flex justify-center">
              <img src={hireOutsourceIcon3} alt="" />
            </div>
            <h1 className="text-2xl font-bold mt-3">DevOps Consultants</h1>
            <p className="text-lg text-slate-200 mt-3">
              DevelopersThrill can provide dedicated DevOps <br />
              engineers for infrastructure planning, <br /> testing, and
              development. Build your
              <br />
              own managed dedicated DevOps team, <br />
              with engineers of any expertise and <br />
              seniority.
            </p>
          </div>
        </div>
        <div className="lg:flex text-center mt-4 items-center justify-evenly">
          <h1 className="lg:text-3xl font-bold text-white text-2xl lg:translate-y-[17px]">
            Your All-In-One AWS Cloud Technology Partner
          </h1>
          <div>
            <button className="btn rounded-full bg-transparent border-2 px-12 mt-10 hover:border-sky-700 hover:bg-transparent border-sky-700">
              Contact us!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HireOutsourceDevOps;
