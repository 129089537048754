import React from "react";
import CompliantImg from "../../../../../Images/FinancialServices/complantImg.png";

const CompliantSection = () => {
  return (
    <div className="container mx-auto px-5 lg:px-14">
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center justify-items-center py-10">
        <div>
          <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
            SOC2 COMPLIANT
          </h1>
          <h1 className="lg:text-3xl text-2xl text-black md:text-2xl font-medium mt-2">
            DevOps Fintech: What is SOC2 and what
            <br />
            is it required to get compliant?
          </h1>
          <p className="text-lg mt-6 text-slate-600 leading-9	">
            <b>SOC2</b>
            is an international standard and auditing procedure that establishes
            <br />
            the basic security, confidentiality availability, privacy, and
            integrity criteria <br /> for storing and managing the information
            that <b>Fintech</b> gets from its <br />
            customers.
          </p>
          <p className="text-lg mt-6 text-slate-600 leading-9	">
            Once a <b>Financial Company</b> gets compliant with <b>SOC2</b>
            top-level security for its customers and their sensitive financial{" "}
            <br /> information. This way, customers are confident that their
            data won’t be <br />
            compromised or lost due to attacks or hacking.
          </p>
        </div>
        <div>
          <img src={CompliantImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default CompliantSection;
