import React from "react";
import Accelete from "../Home/Section/Accelarate/Accelete";
import CompliantSection from "./Section/CompliantSection/CompliantSection";
import DevelopForFintech from "./Section/DevelopForFintech/DevelopForFintech";
import DevOpsBenefitsOfFintech from "./Section/DevOpsBenefitsOfFintech/DevOpsBenefitsOfFintech";
import FinancialBanner from "./Section/FinancialBanner/FinancialBanner";
import ToolsAndTechnologies from "./Section/ToolsAndTechnologies/ToolsAndTechnologies";
import WhatIsDevSecOps from "./Section/WhatIsDevSecOps/WhatIsDevSecOps";

const DavOpsFinancialService = () => {
  return (
    <div>
      <FinancialBanner />
      <DevelopForFintech />
      <CompliantSection />
      <DevOpsBenefitsOfFintech />
      <ToolsAndTechnologies />
      <WhatIsDevSecOps />
      <Accelete />
    </div>
  );
};

export default DavOpsFinancialService;
