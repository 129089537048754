import React from "react";

const TheseDevOpsRoles = () => {
  return (
    <div className="container mx-auto lg:px-20 px-5 my-10">
      <h1 className="text-center text-blue-600 font-bold lg:text-3xl text-2xl">
        HOW COULD YOU USE THESE DEVOPS ROLES?
      </h1>
      <div className="grid gap-0 lg:gap-8 lg:grid-cols-3 grid-cols-1 items-center justify-items-center mt-8">
        <div>
          <div className="text-center p-4 border border-blue-300 rounded-lg hover:rounded-none hover:border-blue-700 duration-200 mt-4">
            <h1 className="text-2xl text-blue-700 font-bold mt-3">
              Site Reliability Engineer
            </h1>
            <p className="text-lg text-slate-600 mt-3">
              DevelopersThrill can provide dedicated DevOps <br />
              engineers for infrastructure planning, <br /> testing, and
              development. Build your
              <br />
              own managed dedicated DevOps team, <br />
              with engineers of any expertise and <br />
              seniority.
            </p>
          </div>
          <div className="text-center p-4 border border-blue-300 rounded-lg hover:rounded-none hover:border-blue-700 duration-200 mt-4">
            <h1 className="text-2xl text-blue-700 font-bold mt-3">
              Security Engineer
            </h1>
            <p className="text-lg text-slate-600 mt-3">
              DevelopersThrill can provide dedicated DevOps <br />
              engineers for infrastructure planning, <br /> testing, and
              development. Build your
              <br />
              own managed dedicated DevOps team, <br />
              with engineers of any expertise and <br />
              seniority.
            </p>
          </div>
        </div>
        <div>
          <div className="text-center p-4 border border-blue-300 rounded-lg hover:rounded-none hover:border-blue-700 duration-200 mt-4">
            <h1 className="text-2xl text-blue-700 font-bold mt-3">
              Cloud Engineer
            </h1>
            <p className="text-lg text-slate-600 mt-3">
              DevelopersThrill can provide dedicated DevOps <br />
              engineers for infrastructure planning, <br /> testing, and
              development. Build your
              <br />
              own managed dedicated DevOps team, <br />
              with engineers of any expertise and <br />
              seniority.
            </p>
          </div>
          <div className="text-center p-4 border border-blue-300 rounded-lg hover:rounded-none hover:border-blue-700 duration-200 mt-4">
            <h1 className="text-2xl text-blue-700 font-bold mt-3">
              Automation Engineer
            </h1>
            <p className="text-lg text-slate-600 mt-3">
              DevelopersThrill can provide dedicated DevOps <br />
              engineers for infrastructure planning, <br /> testing, and
              development. Build your
              <br />
              own managed dedicated DevOps team, <br />
              with engineers of any expertise and <br />
              seniority.
            </p>
          </div>
        </div>
        <div>
          <div className="text-center p-4 border border-blue-300 rounded-lg hover:rounded-none hover:border-blue-700 duration-200 mt-4">
            <h1 className="text-2xl text-blue-700 font-bold mt-3">
              DevOps Architect
            </h1>
            <p className="text-lg text-slate-600 mt-3">
              DevelopersThrill can provide dedicated DevOps <br />
              engineers for infrastructure planning, <br /> testing, and
              development. Build your
              <br />
              own managed dedicated DevOps team, <br />
              with engineers of any expertise and <br />
              seniority.
            </p>
          </div>
          <div className="text-center p-4 border border-blue-300 rounded-lg hover:rounded-none hover:border-blue-700 duration-200 mt-4">
            <h1 className="text-2xl text-blue-700 font-bold mt-3">
              DevOps Evangelist
            </h1>
            <p className="text-lg text-slate-600 mt-3">
              DevelopersThrill can provide dedicated DevOps <br />
              engineers for infrastructure planning, <br /> testing, and
              development. Build your
              <br />
              own managed dedicated DevOps team, <br />
              with engineers of any expertise and <br />
              seniority.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <button className="btn rounded-full bg-transparent border-2 px-12 mt-10 hover:border-sky-700 hover:bg-transparent border-sky-700 text-blue-700">
          Contact us!
        </button>
      </div>
    </div>
  );
};

export default TheseDevOpsRoles;
