import React from "react";
import DevSecOpsImg1 from "../../../../../Images/FinancialServices/devSecOpsImg1.png";
import DevSecOpsImg2 from "../../../../../Images/FinancialServices/devSecOpsImg2.png";
const WhatIsDevSecOps = () => {
  return (
    <div className="bg-[#09365B] px-14">
      <div className="container mx-auto px-5 lg:px-10">
        <div className="grid ga-0 grid-cols-1 lg:grid-cols-2 items-center justify-items-center">
          <div>
            <img src={DevSecOpsImg1} alt="" />
          </div>
          <div>
            <h1 className="text-2xl lg:text-2xl font-medium text-white">
              What is DevSecOps and what does it imply?
            </h1>
            <p className="text-white text-base leading-8 mt-4">
              DevSecOps is the philosophy that focuses on applying total
              security on each <br /> of the development phases, from coding to
              deployment.
            </p>
            <p className="text-white text-base leading-8 mt-3">
              By using DevSecOps, development companies ensure that every code{" "}
              <br /> release and every coding phase are free from
              vulnerabilities or risks and up <br /> to date on the latest
              security standards.
            </p>
          </div>
        </div>
        <div className="grid ga-0 grid-cols-1 lg:grid-cols-2 items-center justify-items-center py-8">
          <div>
            <p className="text-white text-base leading-8 mt-4">
              DevSecOps has gained much popularity in the last years since the
              security
              <br />
              requirements of software businesses have increased due to the
              continually <br />
              changing risks on the cloud. Also, DevSecOps helps software
              businesses to <br />
              Development teams in the security assurance for the final products
              and not <br />
              leaving this task to Operations teams only.
            </p>
            <p className="text-white text-base leading-8 mt-3">
              DevSecOps involves the automation of test cases, the
              implementation of CI/CD
              <br /> pipelines, and vulnerability scan tools such as Snyk and
              Sonarqube, among some
              <br />
              other essential items. These tasks help you get the necessary
              security on your <br />
              code while adding efficiency to your release process.
            </p>
          </div>
          <div>
            <img src={DevSecOpsImg2} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIsDevSecOps;
