import React from "react";
import HowWeWorkBanner from "./Section/Banner/HowWeWorkBanner";
import BlogsPost from "./Section/BlogsPost/BlogsPost";
import BusinessModel from "./Section/BusinessModel/BusinessModel";
import Characteristics from "./Section/Characteristics/Characteristics";
import CollaborationProcess from "./Section/CollaborationProcess/CollaborationProcess";
import HowToWorkDevops from "./Section/HowToWorkDevops/HowToWorkDevops";
import HowWeHelped from "./Section/HowWeHelped/HowWeHelped";
import YouWillGet from "./Section/YouWillGet/YouWillGet";

const HowToWork = () => {
  return (
    <div>
      <HowWeWorkBanner />
      <HowToWorkDevops />
      <YouWillGet />
      <CollaborationProcess />
      <BusinessModel />
      <Characteristics />
      <HowWeHelped />
      <BlogsPost />
    </div>
  );
};

export default HowToWork;
