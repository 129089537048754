import React from "react";
import Service1 from "../../../../../Images/Service/service-1.png";
import Service2 from "../../../../../Images/Service/service-2.png";
import Service3 from "../../../../../Images/Service/service-3.png";
import "./ServicePlan.css";

const ServicePlan = () => {
  return (
    <div className="container mx-auto lg:px-20">
      <div className="text-center my-12">
        <h1 className="md:text-lg lg:text-2xl font-bold text-sky-700">
          OUR SERVICE OFFERING
        </h1>
        <h1 className="lg:text-4xl text-black md:text-3xl font-bold mt-4">
          The Outsourced Services you need... <br />
          The Close-to-home convenience you want
        </h1>
      </div>
      <div>
        <div className="grid grid-cols-1 lg:gap-0 md:gap-0 gap-12 lg:grid-cols-3 md:grid-cols-2 bg-white ">
          <div className="card lg:w-96">
            <div className="card-body">
              <div className="text-3xl font-medium mb-4">
                <div className="flex items-center">
                  <img src={Service1} alt="" className="w-28" />
                  <a href="" className="text-2xl">
                    DevOps
                    <br /> Services
                  </a>
                </div>
              </div>
              <p className="lg:text-lg font-medium">
                Enable continuous delivery & <br /> DevOps practices for faster
                time-to-
                <br /> market.
              </p>

              <p className="lg:text-lg">
                Our DevOps Services help you align development and operations
                teams. You’ll improve code quality, adopt continuous
                integration, and deliver faster.
              </p>
            </div>
          </div>
          <div className="card lg:w-96 flex items-center ">
            <div className="card-body">
              <div className="text-3xl font-medium  mb-4">
                <div className="flex items-center">
                  <img src={Service2} className="w-28" alt="" />
                  <a href="" className="text-2xl">
                    Software
                    <br /> Development
                  </a>
                </div>
              </div>
              <p className="lg:text-lg font-medium">
                Make your software development <br />
                lifecycle more productive.
              </p>
              <p className="lg:text-lg">
                Our Software Development Services help you design, plan, and
                build robust, secure, high-quality applications. You’ll increase
                productivity and efficiency.
              </p>
            </div>
          </div>
          <div className="card lg:w-96">
            <div className="card-body">
              <div className="text-3xl font-medium  mb-2">
                <div className="flex items-center">
                  <img src={Service3} className="w-28" alt="" />
                  <a href="" className="text-2xl">
                    Staff <br /> Augmentation
                  </a>
                </div>
              </div>
              <p className="lg:text-lg font-medium">
                Strengthen your business with our
                <br /> driven nearshore talent and
                <br /> dedicated teams.
              </p>
              <p className="lg:text-lg">
                Our Staff Augmentation Services give you highly-skilled DevOps
                and Software Developers for your IT teams. We’ll join your staff
                and you can focus on business growth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePlan;
