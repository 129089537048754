import React from "react";

const BuildingEffective = () => {
  return (
    <div className="bg-gradient-to-r from-[#11278A] to-[#0071BB] py-10">
      <div className="container mx-auto px-5 lg:px-20">
        <div className="text-center text-white">
          <h1 className="text-2xl font-bold ">
            BUILDING EFFECTIVE SAAS APPLICATIONS
          </h1>
          <h1 className="lg:text-4xl text-3xl font-bold mt-2">
            Implement Technologies that everyone is talking about
          </h1>
        </div>
        <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center justify-items-center my-10">
          <div>
            <div className="border-2 border-white rounded-lg p-5 mt-4">
              <h1 className="text-2xl  font-bold">
                <a href="" className="hover:text-[#ff6700] text-white">
                  Multi-Tenant Architecture
                </a>
              </h1>
              <p className="text-base text-slate-200 mt-4">
                Multi-tenant architecture is a software architecture that allows
                you <br />
                to run multiple single instances of the SaaS software on a
                single <br />
                application system, each instance is a tenant application coming{" "}
                <br />
                from the same software SaaS architecture.
              </p>
            </div>
            <div className="border-2 border-white rounded-lg p-5 mt-4">
              <h1 className="text-2xl  font-bold">
                <a href="" className="hover:text-[#ff6700] text-white">
                  Serverless Computing
                </a>
              </h1>
              <p className="text-base text-slate-200 mt-4">
                Multi-tenant architecture is a software architecture that allows
                you <br />
                to run multiple single instances of the SaaS software on a
                single <br />
                application system, each instance is a tenant application coming{" "}
                <br />
                from the same software SaaS architecture.
              </p>
            </div>
          </div>
          <div>
            <div className="border-2 border-white rounded-lg p-5 mt-4">
              <h1 className="text-2xl  font-bold">
                <a href="" className="hover:text-[#ff6700] text-white">
                  Multi-Tenant Architecture
                </a>
              </h1>
              <p className="text-base text-slate-200 mt-4">
                Multi-tenant architecture is a software architecture that allows
                you <br />
                to run multiple single instances of the SaaS software on a
                single <br />
                application system, each instance is a tenant application coming{" "}
                <br />
                from the same software SaaS architecture.
              </p>
            </div>
            <div className="border-2 border-white rounded-lg p-5 mt-4">
              <h1 className="text-2xl  font-bold">
                <a href="" className="hover:text-[#ff6700] text-white">
                  DevOps for SaaS
                </a>
              </h1>
              <p className="text-base text-slate-200 mt-4">
                Multi-tenant architecture is a software architecture that allows
                you <br />
                to run multiple single instances of the SaaS software on a
                single <br />
                application system, each instance is a tenant application coming{" "}
                <br />
                from the same software SaaS architecture.
              </p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button className="btn rounded-full bg-transparent border-2 px-12 mt-10 hover:border-[#ff6700] hover:bg-transparent border-[#ff6700]">
            Contact us!
          </button>
        </div>
      </div>
    </div>
  );
};

export default BuildingEffective;
