import React from "react";

const TermOfService = () => {
  return (
    <section className="privacy-bg lg:pt-24 pt-20 mb-16">
      <div className="container mx-auto lg:px-14 px-5 ">
        <div className="my-20">
          <div className="text-center">
            <button
              href=""
              class=" cursor-default btn-circle bg-sky-600 hover:bg-sky-600 hover:border-sky-600 border-sky-600 w-20 h-20"
            >
              <i class="fa-regular fa-rectangle-list text-white text-5xl"></i>
            </button>
          </div>
          <div>
            <h1 className="font-bold text-5xl text-center mt-4 text-blue-900">
              Terms of Service
            </h1>
          </div>
        </div>
        <div>
          <p className="text-lg text-slate-500">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ab
            quisquam, nostrum deserunt unde sunt saepe corporis? Incidunt enim
            quasi autem? Lorem ipsum dolor sit amet consectetur adipisicing
            elit.
          </p>
          <br />
          <p className="text-lg text-slate-500">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ab
            quisquam, nostrum deserunt unde sunt saepe corporis? Incidunt enim
            quasi autem? Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ab
            quisquam, nostrum deserunt unde sunt saepe corporis? Incidunt enim
            quasi autem? Lorem ipsum dolor sit amet consectetur adipisicing
            elit.
          </p>
          <br />
          <p className="text-lg text-slate-500">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ab
            quisquam, nostrum deserunt unde sunt saepe corporis? Incidunt enim
            quasi autem? Lorem ipsum dolor sit amet consectetur adipisicing
            elit.
          </p>
          <br />
          <p className="text-lg text-slate-500">
            These Terms of Service were last updated on 7 July 2021.
          </p>
          <br />
          <br />
          <h1 className="font-bold text-blue-900 text-3xl ">
            Limitations of Use
          </h1>
          <p className="text-lg text-slate-500 mt-6">
            By using this website, you warrant on behalf of yourself, your
            users, and other parties you represent that you will not:
          </p>
          <p className="text-lg text-slate-500 ml-6 mt-4">
            1. modify, copy, prepare derivative works of, decompile, or reverse
            engineer any materials and software contained on this website;
          </p>
          <p className="text-lg text-slate-500 ml-6 mt-2">
            2. modify, copy, prepare derivative works of, decompile, or reverse
            engineer any materials and software contained on this website;
          </p>
          <p className="text-lg text-slate-500 ml-6 mt-2">
            3. modify, copy, prepare derivative works of, decompile, or reverse
            engineer any materials and software contained on this website;
          </p>
          <p className="text-lg text-slate-500 ml-6 mt-2">
            4. modify, copy, prepare derivative works of, decompile, or reverse
            engineer any materials and software contained on this website;
          </p>
          <p className="text-lg text-slate-500 ml-6 mt-2">
            5. modify, copy, prepare derivative works of, decompile, or reverse
            engineer any materials and software contained on this website;
          </p>
          <p className="text-lg text-slate-500 ml-6 mt-2">
            6. modify, copy, prepare derivative works of, decompile, or reverse
            engineer any materials and software contained on this website;
          </p>
          <p className="text-lg text-slate-500 ml-6 mt-2">
            7. modify, copy, prepare derivative works of, decompile, or reverse
            engineer any materials and software contained on this website;
          </p>
          <p className="text-lg text-slate-500 ml-6 mt-2">
            8. modify, copy, prepare derivative works of, decompile, or reverse
            engineer any materials and software contained on this website;
          </p>
          <br />
          <h1 className="font-bold text-blue-900 text-3xl ">
            Intellectual Property
          </h1>
          <p className="text-lg text-slate-500 mt-6">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex non
            perspiciatis iusto sapiente? Enim error, sunt aliquam ut
            exercitationem beatae ipsa ad velit nisi. Accusamus saepe
            voluptatibus dolore labore iusto eum explicabo, distinctio facere
            repellendus molestiae dolores nisi consequuntur ullam nostrum quo
            eligendi ea. Aut ipsam quo quia voluptate exercitationem.
          </p>

          <p className="text-lg text-slate-500 mt-6">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex non
            perspiciatis iusto sapiente? Enim error, sunt aliquam ut
            exercitationem beatae ipsa ad velit nisi. Accusamus saepe
            voluptatibus dolore labore iusto eum explicabo, distinctio facere
            repellendus molestiae dolores nisi consequuntur ullam nostrum quo
            eligendi ea. Aut ipsam quo quia voluptate exercitationem.
          </p>
          <br />
          <h1 className="font-bold text-blue-900 text-3xl ">Liability</h1>
          <p className="text-lg text-slate-500 mt-6">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex non
            perspiciatis iusto sapiente? Enim error, sunt aliquam ut
            exercitationem beatae ipsa ad velit nisi. Accusamus saepe
            voluptatibus dolore labore iusto eum explicabo, distinctio facere
            repellendus molestiae dolores nisi consequuntur ullam nostrum quo
            eligendi ea. Aut ipsam quo quia voluptate exercitationem.
          </p>

          <p className="text-lg text-slate-500 mt-6">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex non
            perspiciatis iusto sapiente? Enim error, sunt aliquam ut
            exercitationem beatae ipsa ad velit nisi. Accusamus saepe
            voluptatibus dolore labore iusto eum explicabo, distinctio facere
            repellendus molestiae dolores nisi consequuntur ullam nostrum quo
            eligendi ea. Aut ipsam quo quia voluptate exercitationem.
          </p>
          <p className="text-lg text-slate-500 mt-6">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex non
            perspiciatis iusto sapiente? Enim error, sunt aliquam ut
            exercitationem beatae ipsa ad velit nisi. Accusamus saepe
            voluptatibus dolore labore iusto eum explicabo, distinctio facere
            repellendus molestiae dolores nisi consequuntur ullam nostrum quo
            eligendi ea. Aut ipsam quo quia voluptate exercitationem.
          </p>
          <p className="text-lg text-slate-500 mt-6">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex non
            perspiciatis iusto sapiente? Enim error, sunt aliquam ut
            exercitationem beatae ipsa ad velit nisi. Accusamus saepe
            voluptatibus dolore labore iusto eum explicabo, distinctio facere
            repellendus molestiae dolores nisi consequuntur ullam nostrum quo
            eligendi ea. Aut ipsam quo quia voluptate exercitationem.
          </p>
          <p className="text-lg text-slate-500 mt-6">
            Because some jurisdictions do not allow limitations on implied
            warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you.
          </p>
          <br />
          <h1 className="font-bold text-blue-900 text-3xl ">
            Accuracy of Materials
          </h1>
          <p className="text-lg text-slate-500 mt-6">
            Because some jurisdictions do not allow limitations on implied
            warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you. Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Dolorem numquam
            qui nostrum distinctio nemo perferendis adipisci, repellat nobis
            quae atque commodi alias, dicta consectetur eaque ducimus corporis
            tempora sint. Architecto?
          </p>
          <br />
          <h1 className="font-bold text-blue-900 text-3xl ">Links</h1>
          <p className="text-lg text-slate-500 mt-6">
            Because some jurisdictions do not allow limitations on implied
            warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you. Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Dolorem numquam
            qui nostrum distinctio nemo perferendis adipisci, repellat nobis
            quae atque commodi alias, dicta consectetur eaque ducimus corporis
            tempora sint. Architecto?
          </p>
          <br />
          <h1 className="font-bold text-blue-900 text-3xl ">
            Right to Terminate
          </h1>
          <p className="text-lg text-slate-500 mt-6">
            We may suspend or terminate your right to use our website and
            terminate these Terms of Service immediately upon written notice to
            you for any breach of these Terms of Service.
          </p>
          <br />
          <h1 className="font-bold text-blue-900 text-3xl ">Severance</h1>
          <p className="text-lg text-slate-500 mt-6">
            Any term of these Terms of Service which is wholly or partially void
            or unenforceable is severed to the extent that it is void or
            unenforceable. The validity of the remainder of these Terms of
            Service is not affected.
          </p>
          <br />
          <h1 className="font-bold text-blue-900 text-3xl ">Governing Law</h1>
          <p className="text-lg text-slate-500 mt-6">
            These Terms of Service are governed by and construed in accordance
            with the laws of México. You irrevocably submit to the exclusive
            jurisdiction of the courts in that State or location.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TermOfService;
