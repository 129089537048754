import React from "react";
import BlogsImg1 from "../../../../../Images/AWSManageService/BlogsImg1.jpg";
import BlogsImg2 from "../../../../../Images/AWSManageService/BlogsImg2.jpg";
const BlogPosts = () => {
  return (
    <div className="bg-[#09365B] py-10">
      <div className="container mx-auto px-5 lg:px-20">
        <div className="grid gap-0 grid-cols-1 lg:grid-cols-3 items-center justify-items-center ">
          <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-xl hover:shadow-2xl mt-10 lg:mt-0">
            <figure className="group relative">
              <img src={BlogsImg1} className="w-full" />
              <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-blue-900 opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
                <a class="mt-5 px-8 py-3  text-white" href="#">
                  <i class="fa-solid fa-arrow-right text-2xl"></i>
                </a>
              </div>
            </figure>
            <div className="p-4">
              <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
                <a className=" hover:text-blue-700 duration-200" href="">
                  AWS data transfer – How
                </a>
              </h1>

              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Aliquid, a? Provident ratione fugiat maiores quas...
              </p>
              <div className="mt-4">
                <a className="text-sm text-blue-900" href="">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="card lg:w-80 md:w-80 w-full bg-base-100 shadow-xl hover:shadow-2xl mt-10 lg:mt-0">
            <figure className="group relative">
              <img src={BlogsImg2} className="w-full" />
              <div class="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-blue-900 opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
                <a class="mt-5 px-8 py-3  text-white" href="#">
                  <i class="fa-solid fa-arrow-right text-2xl"></i>
                </a>
              </div>
            </figure>
            <div className="p-4">
              <h1 className=" text-2xl  font-medium leading-7 text-black mt-2 mb-2">
                <a className=" hover:text-blue-700 duration-200" href="">
                  How to connect to an EC2
                </a>
              </h1>

              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Aliquid, a? Provident ratione fugiat maiores quas...
              </p>
              <div className="mt-4">
                <a className="text-sm text-blue-900" href="">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="mt-10 lg:mt-0">
            <h1 className="text-lg font-bold text-white">BLOG POSTS</h1>
            <h1 className="font-medium text-3xl lg:text-4xl mt-2 text-white">
              Cloud knowledge <br />
              for everyone
            </h1>
            <p className="text-lg text-white mt-2">
              Learn more about the experts in the cloud
            </p>
            <button className="btn rounded-full bg-transparent border-2 px-12 mt-10 hover:border-[#ff6700] hover:bg-transparent border-[#ff6700]">
              Contact us!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPosts;
