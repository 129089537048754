import React from "react";

const AksedQuestion = () => {
  return (
    <div className="pt-10">
      <div className="text-center">
        <h1 className="text-3xl lg:text-5xl font-medium">
          Frequent Asked Questions
        </h1>
      </div>
      <div className="container mx-auto lg:px-20 px-5 mt-10">
        <div className="grid gap-0 lg:gap-12 grid-cols-1 lg:grid-cols-2 py-8 items-center">
          <div>
            <div
              tabIndex={0}
              className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box drop-shadow-xl"
            >
              <div className="collapse-title text-xl font-medium">
                What are AWS Managed Services?
              </div>
              <div className="collapse-content">
                <p className="text-xl">
                  AMS is a set of automation and optimization cloud operations,
                  composed of AWS best practices, Security compliance,
                  Optimization of infrastructure, Reliability & scalability,
                  Support monitoring 24×7, AWS migration, AWS consultancy, and
                  Automation & optimization of infrastructure.
                </p>
              </div>
            </div>

            <br />
            <div
              tabIndex={0}
              className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box drop-shadow-xl mt-4"
            >
              <div className="collapse-title text-xl font-medium">
                What is the Role of AWS Service Manager?
              </div>
              <div className="collapse-content">
                <p className="text-xl">
                  The AWS Service Manager is a dedicated service manager that
                  will provide preliminary managed AWS consulting services.
                  Their job is to study the existing workflows of your business,
                  develop roadmaps, set priorities and facilitate communication
                  between your business elements and engineers in charge.
                </p>
              </div>
            </div>
            <br />

            <div
              tabIndex={0}
              className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box drop-shadow-xl mt-4"
            >
              <div className="collapse-title text-xl font-medium">
                What does fully managed services mean on AWS?
              </div>
              <div className="collapse-content">
                <p className="text-xl">
                  AWS fully managed services mean that AWS will operate elements
                  on your behalf. When deploying or migrating an application to
                  the AWS cloud, you can choose between a self-managed scenario
                  or a fully-managed. This second one refers to having all
                  underlying services managed by a service provider, in this
                  case AWS. When relying on fully-managed services you stop
                  worrying about backups, patches and fixes; this option is
                  convenient and easy to manage through a team of cloud experts.
                </p>
              </div>
            </div>

            <br />
            <div
              tabIndex={0}
              className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box drop-shadow-xl mt-4"
            >
              <div className="collapse-title text-xl font-medium">
                How much does AMS cost?
              </div>
              <div className="collapse-content">
                <p className="text-xl">
                  AWS Managed Services costs vary and depend on the service that
                  you are using. These prices can be calculated by different
                  methods, but the main one is for the time of usage, as Amazon
                  (AWS) says so, you pay for what you use. Hours are the main
                  factor on How AWS Pricing works, followed by the minutes and
                  seconds of use of certain services. Determining the cost for
                  any application can be a challenge. Every cost varies
                  according to the type of app in the Cloud and the type of work
                  that it manages. ClickIT can help you optimize the AWS pricing
                  and reduce your cloud investment, to prevent billing
                  surprises.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-10 lg:mt-0">
            <h1 className="md:text-sm lg:text-lg font-bold text-sky-700">
              CLOUD MANAGED
            </h1>
            <h1 className="md:text-3xl text-2xl lg:text-5xl font-medium text-black">
              Have any more <br />
              Questions?
            </h1>
            <p className="text-xl mt-2">
              Don’t be shy and ask any questions you have
            </p>
            <button className="btn rounded-full bg-transparent border-2 px-12 mt-10 hover:border-sky-500 hover:bg-transparent border-sky-500 text-blue-800">
              Talk with an expert
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AksedQuestion;
