import React from "react";
import "./DevelopForFintech.css";
import financialServiceIcon1 from "../../../../../Images/FinancialServices/financialServiceIcon1.png";
import financialServiceIcon2 from "../../../../../Images/FinancialServices/financialServiceIcon2.png";
import financialServiceIcon3 from "../../../../../Images/FinancialServices/financialServiceIcon3.png";
import financialServiceIcon4 from "../../../../../Images/FinancialServices/financialServiceIcon4.png";
import financialServiceIcon5 from "../../../../../Images/FinancialServices/financialServiceIcon5.png";
import financialServiceIcon6 from "../../../../../Images/FinancialServices/financialServiceIcon6.png";

const DevelopForFintech = () => {
  return (
    <div className="developForFintech-container">
      <div className="container mx-auto px-5 lg:px-20">
        <div className="text-center mt-12 mb-10">
          <h1 className="md:text-lg text-xl lg:text-2xl font-bold text-sky-700">
            DEVOPS FOR FINTECH
          </h1>
          <h1 className="lg:text-4xl text-2xl text-black md:text-3xl font-bold mt-2">
            Can support a wide variety of financial businesses
          </h1>
        </div>
        <div className="lg:flex items-center justify-evenly my-8">
          <div>
            <div className="border border-sky-200 rounded-lg mt-3 hover:rounded-none hover:border-blue-600 duration-200 p-6  bg-white lg:w-72 w-full">
              <div className="flex justify-center">
                <img className="w-32" src={financialServiceIcon1} alt="" />
              </div>
              <h1 className="text-center font-medium text-blue-700 text-2xl mt-3">
                Banking
              </h1>
            </div>
            <div className="border border-sky-200 rounded-lg mt-3 hover:rounded-none hover:border-blue-600 duration-200 p-6  bg-white lg:w-72 w-full">
              <div className="flex justify-center">
                <img className="w-32" src={financialServiceIcon2} alt="" />
              </div>
              <h1 className="text-center font-medium text-blue-700 text-2xl mt-3">
                Payment
              </h1>
            </div>
          </div>
          <div>
            <div className="border border-sky-200 rounded-lg mt-3 hover:rounded-none hover:border-blue-600 duration-200 p-6 bg-white lg:w-72 w-full">
              <div className="flex justify-center">
                <div className="flex justify-center">
                  <img className="w-32" src={financialServiceIcon3} alt="" />
                </div>
              </div>
              <h1 className="text-center font-medium text-blue-700 text-2xl mt-3">
                Payment
              </h1>
            </div>
            <div className="border border-sky-200 rounded-lg mt-3 hover:rounded-none hover:border-blue-600 duration-200 p-6 bg-white lg:w-72 w-full">
              <div className="flex justify-center">
                <img className="w-32" src={financialServiceIcon4} alt="" />
              </div>
              <h1 className="text-center font-medium text-blue-700 text-2xl mt-3">
                Insurance
              </h1>
            </div>
          </div>
          <div>
            <div className="border border-sky-200 rounded-lg mt-3 hover:rounded-none hover:border-blue-600 duration-200 p-6 bg-white lg:w-72 w-full">
              <div className="flex justify-center">
                <div className="flex justify-center">
                  <img className="w-32" src={financialServiceIcon5} alt="" />
                </div>
              </div>
              <h1 className="text-center font-medium text-blue-700 text-2xl mt-3">
                Loans
              </h1>
            </div>
            <div className="border border-sky-200 rounded-lg mt-3 hover:rounded-none hover:border-blue-600 duration-200 p-6 bg-white lg:w-72 w-full">
              <div className="flex justify-center">
                <div className="flex justify-center">
                  <img className="w-32" src={financialServiceIcon6} alt="" />
                </div>
              </div>
              <h1 className="text-center font-medium text-blue-700 text-2xl mt-3">
                Capital markets
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopForFintech;
