import React from "react";
import "./ContactPage.css";

const ContactPage = () => {
  return (
    <div className="contact-Banner-bg py-15 mt-20 lg:mt-24">
      <div className="container mx-auto px-5 lg:px-20 py-10">
        <div className="text-center py-10">
          <h1 className="font-bold text-4xl lg:text-5xl text-white ">
            Innovation is a Click away
          </h1>
          <p className="text-lg text-white mt-4 text-center">
            We want to hear from you & your project. Pitch us, and let’s have a
            talk!
          </p>
        </div>
        <div className="lg:px-32">
          <div className="shadow-2xl p-8  lg:flex justify-center rounded-2xl items-center bg-white mt-8">
            <form action="" className="mt-10px w-full">
              <div className="lg:flex md:flex items-center">
                <div className="lg:mr-7 md:mr-7 mb-5  lg:mb-0 lg:w-6/12">
                  <label
                    className="text-base text-slate-600 font-medium pb-2"
                    htmlFor=""
                  >
                    Name<sup className="text-red-500">*</sup>
                  </label>
                  <br />
                  <input
                    placeholder="Full Name"
                    className="input w-full border-slate-600 border-0 border-b rounded-none"
                    type="text"
                  />
                </div>
                <div className=" lg:w-6/12 mt-6 lg:mt-0">
                  <label
                    className="text-base text-slate-600 font-medium pb-2"
                    htmlFor=""
                  >
                    Phone<sup className="text-red-500">*</sup>
                  </label>
                  <br />
                  <input
                    placeholder="+1 000 000 000"
                    className="input w-full border-slate-600 border-0 border-b rounded-none"
                    type="text"
                  />
                </div>
              </div>
              <br />
              <div className="lg:w-full mt-6 lg:mt-0">
                <label
                  className="text-base text-slate-600 font-medium pb-2"
                  htmlFor=""
                >
                  Email<sup className="text-red-500">*</sup>
                </label>
                <br />
                <input
                  placeholder="example@gmail.com"
                  type="gmail"
                  className="input border-slate-600  border-0 border-b rounded-none  w-full "
                />
              </div>
              <br />
              <div className="lg:flex md:flex items-center">
                <div className="lg:mr-7 md:mr-7 mb-5 lg:mb-0 lg:w-6/12 mt-1">
                  <label
                    className="text-base text-slate-600 font-medium pb-2"
                    htmlFor=""
                  >
                    Company<sup className="text-red-500">*</sup>
                  </label>
                  <br />
                  <input
                    placeholder="Your Company Name"
                    className="input w-full border-slate-600 border-0 border-b rounded-none"
                    type="text"
                  />
                </div>
                <div className="lg:w-6/12">
                  <label
                    className="text-base text-slate-600 font-medium"
                    htmlFor=""
                  >
                    Type of Inquiry<sup className="text-red-500">*</sup>
                  </label>
                  <br />
                  <select
                    name=""
                    id=""
                    className="border-black border-0 rounded-none border-b select w-full "
                  >
                    <option value=""></option>
                    <option value="">AWS Consulting</option>
                    <option value="">AWS Consulting</option>
                    <option value="">AWS Consulting</option>
                  </select>
                </div>
              </div>
              <div className="lg:full mt-5">
                <label
                  className="text-base text-slate-600 font-medium"
                  htmlFor=""
                >
                  Message <sup className="text-red-500">*</sup>
                </label>
                <br />

                <textarea
                  placeholder="Describe your project here"
                  name=""
                  className="border-0 border-b w-full border-black active:border-0 p-3"
                  id=""
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
              <div className="mt-5 w-full">
                <button className="btn rounded-full w-full  bg-[#ff6700] hover:bg-[#ff6700] border-[#ff6700] hover:border-[#ff6700]">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
