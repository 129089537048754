import React from "react";
import DevOpsConsultingImg from "../../../../../Images/DevOpsConsulting/devOpsConsulting.png";
import AdvantageImg1 from "../../../../../Images/DevOpsConsulting/advantageImg1.png";
import AdvantageImg2 from "../../../../../Images/DevOpsConsulting/advantageImg2.png";
import AdvantageImg3 from "../../../../../Images/DevOpsConsulting/advantageImg3.png";
const DevOpsCouldService = () => {
  return (
    <div className="container mx-auto lg:px-20 px-5">
      <div className="grid gap-0 grid-cols-1 lg:grid-cols-2 items-center justify-items-center">
        <div>
          <div className=" lg:my-12 mt-6 lg:mt-12">
            <h1 className="md:text-lg lg:text-2xl font-bold text-sky-700">
              DEVOPS CONSULTING
            </h1>
            <h1 className="lg:text-5xl text-black md:text-3xl font-medium mt-2">
              Cloud Managed Services
            </h1>
            <p className="text-lg text-slate-600 mt-5">
              We help you build, maintain, support, and scale your cloud <br />
              infrastructure, implementing the best and most modern tools for
              your
              <br />
              cloud environments as a top DevOps consulting company.
            </p>
          </div>
        </div>
        <div>
          <img src={DevOpsConsultingImg} alt="" />
        </div>
      </div>
      <div className="mt-6">
        <h1 className="lg:text-5xl text-black md:text-3xl font-medium mt-2 text-center">
          Advantages of integrating AWS DevOps to your <br /> company
        </h1>
        <div className="grid gap-0 grid-cols-1 lg:grid-cols-4 md:grid-cols-2 my-5 items-center justify-items-center">
          <div className="text-blue-700 text-center">
            <h1 className="font-bold lg:text-6xl text-5xl">25x</h1>
            <p className="text-2xl mt-6">Deploy Code Faster</p>
          </div>
          <div>
            <img src={AdvantageImg1} alt="" />
          </div>
          <div>
            {" "}
            <img src={AdvantageImg2} alt="" />
          </div>
          <div>
            <img src={AdvantageImg3} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevOpsCouldService;
