import React from "react";
import Bannerimg from "../../../../../Images/DevOpsConsulting/devOpsConsultingBanner.png";

const DevOpsConsultingBanner = () => {
  return (
    <div className="container mx-auto px-5 lg:px-20 mt-20 lg:mt-24">
      <div className="grid gap grid-cols-1 lg:grid-cols-2 py-16 items-center justify-items-center">
        <div>
          <img src={Bannerimg} alt="" />
        </div>
        <div>
          <h1 className="text-xl font-medium text-sky-700">
            Transform your applications
          </h1>
          <h1 className="lg:text-6xl text-4xl font-bold text-sky-700">
            DevOps
          </h1>
          <p className="text-4xl mt-5 font-medium text-sky-700">
            Consulting Services
          </p>
          <button className="btn rounded-full bg-transparent px-12 mt-10 hover:border-sky-800 text-sky-800 border-2 border-sky-700 bg:transparent hover:bg-transparent">
            Contact us!
          </button>
        </div>
      </div>
    </div>
  );
};

export default DevOpsConsultingBanner;
